<template>
  <button
    class="pushable"
    :type="type"
    :native-type="nativeType"
    :class="btnStyle === 'box' ? 'box-btn' : ''"
    @click="itserveButtonClicked"
  >
    <span class="shadow"></span>
    <span class="edge"></span>
    <span class="front" :class="icon ? 'icon-inside' : 'no-icon'">
      <div class="btn-icon-wraper" :class="icon ? 'icon-inside' : 'no-icon'">
        <div
          class="nav-btn-icon"
          v-if="icon"
          :style="{ backgroundColor: iconColor }"
        >
          <slot></slot>
        </div>
      </div>
      <div class="btn-content" :class="icon ? 'icon-inside' : 'no-icon'">
        {{ value }}
        <template v-if="animated">
          <v-icon name="hand-pointer" class="animated-pointer-icon"></v-icon>
        </template>
      </div>
    </span>
  </button>
</template>
<script>
import "vue-awesome/icons/hand-pointer";
export default {
  name: "itserve-button",
  props: {
    type: String,
    value: String,
    nativeType: String,
    icon: Boolean,
    animated: Boolean,
    iconColor: String,
    btnStyle: String,
  },
  methods: {
    itserveButtonClicked() {
      this.nativeSendEvent("bottomBar", {});
      this.$emit("click");
    },
  },
};
</script>
<style scoped>
.box-btn .btn-icon-wraper,
.box-btn .nav-btn-icon {
  display: inline-block !important;
}
.pushable {
  position: relative;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
  transition: filter 250ms;
}
.shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: hsl(0deg 0% 0% / 0.35);
  will-change: transform;
  transform: translateY(2px);
  transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
}
.edge {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  /* background: linear-gradient(
    to left,
    hsl(211deg 100% 16%) 0%,
    hsl(211deg 100% 32%) 8%,
    hsl(211deg 100% 32%) 92%,
    hsl(211deg 100% 16%) 100%
  ); */
}
.btn-content {
  height: 50px;
  line-height: 50px;
}
.front {
  display: block;
  position: relative;
  border-radius: 12px;
  font-size: 18px;
  color: white;
  background: hsl(211deg 56% 45%);
  will-change: transform;
  transform: translateY(-4px);
  transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
}
.pushable:hover {
  filter: brightness(110%);
}
.pushable:hover .front {
  transform: translateY(-6px);
  transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
}
.pushable:active .front {
  transform: translateY(-2px);
  transition: transform 34ms;
}
.pushable:hover .shadow {
  transform: translateY(4px);
  transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
}
.pushable:active .shadow {
  transform: translateY(1px);
  transition: transform 34ms;
}
.pushable:focus:not(:focus-visible) {
  outline: none;
}
</style>

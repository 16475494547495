<template>
  <div class="itserve-info-message">
    <span class="info-content">
      <slot></slot>
    </span>
  </div>
</template>

<script>
export default {
  name: "InfoMessage",
};
</script>
<style scoped>
.itserve-info-message {
  margin-top: 15px;
  margin-bottom: 15px;
  color: #0b0b0b;
  font-size: 18px;
  text-align: center;
  max-width: 337px;
}
.info-content {
  max-width: 317px;
  display: inline-block;
  text-align: left;
  padding: 10px;
}
</style>

<template>
  <van-row class="page-wrapper" justify="center">
    <van-col
      class="itserve-center round-shadow-btn verification-page"
      span="24"
    >
      <van-row>
        <van-col span="24" class="date-block apply-loader mt-25">
          {{ $t("surnameVerification.surnamePlaceholder") }}
        </van-col>
      </van-row>
      <template v-if="!showDialogSurname">
        <itserve-input
          v-model="verification.surname"
          name="surname"
          type="text"
          class="apply-loader verification-input"
        />
      </template>
    </van-col>
    <van-col span="24" class="itserve-center">
      <itserve-button
        type="primary"
        :value="$t('verification.verifyButton')"
        class="itserve-button-wide verification-button mt-15 apply-loader"
        @click="verificate"
      ></itserve-button>
    </van-col>
    <simple-dialog
      v-model="showDialog"
      :title="dialogMessage"
      :success="success"
      @change="goToAccount"
    />
    <simple-dialog
      v-model="showDialogSurname"
      :title="$t('surnameVerification.dialogSurnameValidator')"
      :success="false"
      @change="goToAccount"
    />
  </van-row>
</template>

<script>
import ItserveInput from "@/components/forms/ItserveInput";
import ItserveButton from "@/components/forms/ItserveButton";
import sendVerificationRequest from "@/mixins/verification/sendVerificationRequest";
import "vue-awesome/icons/check";
import SimpleDialog from "@/components/content/SimpleDialog";
export default {
  name: "SurnameVerification",
  components: { SimpleDialog, ItserveButton, ItserveInput },
  mixins: [sendVerificationRequest],
  data() {
    return {
      showDialogSurname: false,
      verification: {
        ls: this.$route.params.account,
        surname: null,
        type: "surname",
      },
    };
  },
};
</script>

<style scoped>
@import "../../../../../node_modules/vant/lib/popup/index.css";
@import "../../../../../node_modules/vant/lib/dialog/index.css";
@import "../../../../../node_modules/vant/lib/overlay/index.css";
.verification-page {
  min-height: 114px;
}
</style>

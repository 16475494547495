<template>
  <svg
    width="100%"
    height="100%"
    style="margin-top: 2px"
    viewBox="-32 -22 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.70703 36.2744V20.6113H48.2917V36.2744H7.70703ZM46.2548 34.2238V22.6619H39.0158V34.2238H46.2548ZM36.9693 34.2238V22.6619H29.2617V34.2238H36.9693ZM27.2152 34.2238V22.6619H19.498V34.2238H27.2152ZM17.4611 34.2238V22.6619H9.75348V34.2238H17.4611Z"
      fill="white"
    />
    <path
      d="M8.25293 21.1602V35.7354H47.7475V21.1602H8.25293ZM28.7174 22.1229H37.5152V34.7727H28.7174V22.1229V22.1229ZM27.7611 34.7727H18.9633V22.1229H27.7611V34.7727ZM9.20921 22.1229H18.007V34.7727H9.20921V22.1229V22.1229ZM46.7912 34.7727H38.4619V22.1229H46.7912V34.7727Z"
      fill="white"
    />
    <path
      d="M14.1626 17.2611V11.6486H20.541V17.2611H14.1626ZM18.5041 15.201V13.6992H16.209V15.201H18.5041Z"
      fill="white"
    />
    <path
      d="M20.0046 12.1878H14.7163V16.7125H20.0046V12.1878ZM19.0483 15.7498H15.6726V13.1505H19.0483V15.7498Z"
      fill="white"
    />
    <path
      d="M24.624 17.2611V11.6486H31.0024V17.2611H24.624ZM28.9656 15.201V13.6992H26.6705V15.201H28.9656Z"
      fill="white"
    />
    <path
      d="M30.4577 12.1878H25.1694V16.7125H30.4577V12.1878ZM29.5014 15.7498H26.1257V13.1505H29.5014V15.7498Z"
      fill="white"
    />
    <path
      d="M35.3345 17.2611V11.6486H41.7129V17.2611H35.3345ZM39.6664 15.201V13.6992H37.3714V15.201H39.6664Z"
      fill="white"
    />
    <path
      d="M41.1681 12.1878H35.8799V16.7125H41.1681V12.1878ZM40.2119 15.7498H36.8362V13.1505H40.2119V15.7498Z"
      fill="white"
    />
    <path
      d="M18.8292 64V57.2708C7.55464 53.3237 0 42.6763 0 30.6426C0 18.5319 7.61202 7.8556 18.9822 3.95668V0H37.0178V3.95668C48.388 7.8556 56 18.5319 56 30.6426C56 42.6474 48.474 53.2852 37.2281 57.2515V64H18.8292ZM35.1913 61.9398V57.8773C32.8388 58.5126 30.429 58.8303 28 58.8303C25.5997 58.8303 23.1995 58.5126 20.8661 57.8965V61.9398H35.1913ZM28 4.50541C13.6844 4.50541 2.03689 16.231 2.03689 30.6426C2.03689 44.6402 13.1967 56.231 27.0246 56.7509V40.9627H29.0615V56.7413C42.832 56.1733 53.9535 44.5728 53.9535 30.6426C53.9631 16.231 42.3156 4.50541 28 4.50541ZM28 2.45487C30.3429 2.45487 32.6858 2.75331 34.9713 3.35018V2.05054H21.0287V3.34055C23.3142 2.75331 25.6571 2.45487 28 2.45487Z"
      fill="white"
    />
    <path
      d="M55.4552 30.6426C55.4552 18.3778 47.4798 7.96146 36.473 4.35135V0.548706H19.5277V4.36098C8.52082 7.96146 0.54541 18.3778 0.54541 30.6426C0.54541 42.8495 8.45388 53.2274 19.3746 56.876V63.4512H36.6929V56.8568C47.585 53.1889 55.4552 42.8303 55.4552 30.6426ZM20.4839 1.5114H35.5167V4.06254C33.126 3.37903 30.6014 3.00358 28.0003 3.00358C25.3897 3.00358 22.8746 3.37903 20.4839 4.06254V1.5114ZM35.7367 62.4885H20.3309V57.1841C22.7695 57.8965 25.3419 58.2912 28.0003 58.2912C30.6875 58.2912 33.279 57.8965 35.7367 57.1648V62.4885V62.4885ZM28.5263 57.3092V41.5114H27.57V57.3092C13.1588 57.0782 1.50169 45.2081 1.50169 30.6426C1.50169 15.9326 13.3883 3.96627 28.0003 3.96627C42.6123 3.96627 54.499 15.9326 54.499 30.6426C54.499 45.1793 42.8992 57.0204 28.5263 57.3092Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "send-indications-icon",
};
</script>

<style scoped></style>

import "@/plugins/nativescript-webview-interface.js";
let oWebViewInterface = window.nsWebViewInterface;

export const state = {
  flashlightEnabled: false,
};

export const mutations = {
  SET_FLASHLIGHT_ENABLED(state) {
    state.flashlightEnabled = true;
  },
  SET_FLASHLIGHT_DISABLED(state) {
    state.flashlightEnabled = false;
  },
};
export const actions = {
  enableFlashlight({ commit }) {
    commit("SET_FLASHLIGHT_ENABLED");
    oWebViewInterface.emit("enableFlashlight");
    oWebViewInterface.emit("flashlightControl", { isEnabled: true });
  },
  disableFlashlight({ state, commit }) {
    if (state.flashlightEnabled) {
      commit("SET_FLASHLIGHT_DISABLED");
      oWebViewInterface.emit("enableFlashlight");
      oWebViewInterface.emit("flashlightControl", { isEnabled: false });
    }
  },
};

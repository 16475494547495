<template>
  <van-row class="page-wrapper" justify="center">
    <payment-history-search-form />
  </van-row>
</template>
<script>
import PaymentHistorySearchForm from "@/components/history/PaymentHistorySearchForm";
export default {
  name: "History",
  components: { PaymentHistorySearchForm },
};
</script>

<template>
  <svg
    width="100%"
    height="100%"
    style="margin-top: 6px"
    viewBox="-27 -15 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.16685 64V58.2795H0V6.46288H11.5821V0H18.5955V6.46288H27.0739V0H34.0873V6.46288H42.1237V0H49.1371V6.46288H61.1613V14.8646H67.3598V64H5.16685ZM65.5132 62.1397V16.7249H61.1613V58.2707H7.01339V62.131H65.5132V62.1397ZM59.3234 56.4192V8.33188H49.1457V15.6943H42.1324V8.33188H34.096V15.6943H27.0826V8.33188H18.6041V15.6943H11.5907V8.33188H1.84654V56.4192H59.3234ZM47.2905 13.8253V1.86026H43.9789V13.8253H47.2905V13.8253ZM32.2408 13.8253V1.86026H28.9292V13.8253H32.2408V13.8253ZM16.7489 13.8253V1.86026H13.4373V13.8253H16.7489V13.8253Z"
      fill="white"
    />
    <path
      d="M60.6768 15.3626V6.96081H48.6526V0.497925H42.6188V6.96081H33.6028V0.497925H27.5691V6.96081H18.111V0.497925H12.0772V6.96081H0.495117V57.7818H5.66197V63.5023H66.8839V15.3626H60.6768ZM43.4857 1.37129H47.7857V14.3233H43.4857V1.37129ZM28.436 1.37129H32.7359V14.3233H28.436V1.37129ZM12.9441 1.37129H17.244V14.3233H12.9441V1.37129ZM1.36204 7.83417H12.0772V15.1966H18.111V7.83417H27.5691V15.1966H33.6028V7.83417H42.6188V15.1966H48.6526V7.83417H59.8098V56.9084H1.36204V7.83417ZM66.0083 62.6377H6.52022V57.7905H60.6768V16.2359H66.0083V62.6377Z"
      fill="white"
    />
    <path
      d="M32.5 36.3144V29.3799H39.3834V36.3144H32.5ZM37.5281 34.4542V31.2489H34.3465V34.4542H37.5281Z"
      fill="white"
    />
    <path
      d="M32.9868 35.8166H38.8906V29.869H32.9868V35.8166ZM33.8537 30.7511H38.0236V34.952H33.8537V30.7511Z"
      fill="white"
    />
    <path
      d="M20.3813 36.3144V29.3799H27.2647V36.3144H20.3813ZM25.4182 34.4542V31.2489H22.2366V34.4542H25.4182Z"
      fill="white"
    />
    <path
      d="M20.875 35.8166H26.7787V29.869H20.875V35.8166ZM21.7419 30.7511H25.9118V34.952H21.7419V30.7511Z"
      fill="white"
    />
    <path
      d="M8.26953 36.3144V29.3799H15.1529V36.3144H8.26953ZM13.3063 34.4542V31.2489H10.1247V34.4542H13.3063Z"
      fill="white"
    />
    <path
      d="M8.76465 35.8166H14.6684V29.869H8.76465V35.8166ZM9.63157 30.7511H13.8015V34.952H9.63157V30.7511Z"
      fill="white"
    />
    <path
      d="M44.6118 36.3144V29.3799H51.4952V36.3144H44.6118ZM49.6486 34.4542V31.2489H46.467V34.4542H49.6486Z"
      fill="white"
    />
    <path
      d="M45.0972 35.8166H51.0009V29.869H45.0972V35.8166ZM45.9641 30.7511H50.134V34.952H45.9641V30.7511Z"
      fill="white"
    />
    <path
      d="M32.5 25.7204V18.7859H39.3834V25.7204H32.5ZM37.5281 23.8602V20.6549H34.3465V23.8602H37.5281Z"
      fill="white"
    />
    <path
      d="M32.9868 25.2315H38.8906V19.2839H32.9868V25.2315ZM33.8537 20.1573H38.0236V24.3582H33.8537V20.1573Z"
      fill="white"
    />
    <path
      d="M44.6118 25.7204V18.7859H51.4952V25.7204H44.6118ZM49.6486 23.8602V20.6549H46.467V23.8602H49.6486Z"
      fill="white"
    />
    <path
      d="M45.0972 25.2315H51.0009V19.2839H45.0972V25.2315ZM45.9641 20.1573H50.134V24.3582H45.9641V20.1573Z"
      fill="white"
    />
    <path
      d="M32.5 46.9082V39.9737H39.3834V46.9082H32.5ZM37.5281 45.0392V41.834H34.3465V45.0392H37.5281Z"
      fill="white"
    />
    <path
      d="M32.9868 46.4106H38.8906V40.463H32.9868V46.4106ZM33.8537 41.3364H38.0236V45.5372H33.8537V41.3364Z"
      fill="white"
    />
    <path
      d="M20.3813 46.9082V39.9737H27.2647V46.9082H20.3813ZM25.4182 45.0392V41.834H22.2366V45.0392H25.4182Z"
      fill="white"
    />
    <path
      d="M20.875 46.4106H26.7787V40.463H20.875V46.4106ZM21.7419 41.3364H25.9118V45.5372H21.7419V41.3364Z"
      fill="white"
    />
    <path
      d="M8.26953 46.9082V39.9737H15.1529V46.9082H8.26953ZM13.3063 45.0392V41.834H10.1247V45.0392H13.3063Z"
      fill="white"
    />
    <path
      d="M8.76465 46.4106H14.6684V40.463H8.76465V46.4106ZM9.63157 41.3364H13.8015V45.5372H9.63157V41.3364Z"
      fill="white"
    />
    <path
      d="M44.6118 46.9082V39.9737H51.4952V46.9082H44.6118ZM49.6486 45.0392V41.834H46.467V45.0392H49.6486Z"
      fill="white"
    />
    <path
      d="M45.0972 46.4106H51.0009V40.463H45.0972V46.4106ZM45.9641 41.3364H50.134V45.5372H45.9641V41.3364Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "payment-history-icon-box",
};
</script>

<style scoped></style>

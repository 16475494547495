<template>
  <van-row class="page-wrapper add-account-page" justify="center">
    <van-col
        span="24"
        class="itserve-center round-shadow-btn flex-display-fix mb-25"
    >
      <div class="place-content-center">
        <info-message class="apply-loader add-account-page-message">{{
            $t("addAccount.building")
          }}</info-message>
      </div>
      <div class="place-content-center">
        <info-message class="apply-loader add-account-page-message">{{
            $t("addAccount.wait")
          }}</info-message>
      </div>
      <van-form>
        <v-text-field
            :placeholder="$t('addAccount.inputBuilding')"
            autocomplete="off"
            v-model="building"
            class="mt-10"
            type="text"
        ></v-text-field>
        <v-card v-if="listBuilding">
          <v-virtual-scroll
              height="200"
              item-height="64"
              :items="BUILDS"
          >
            <template v-slot:default="{ item }">
              <v-list-item :key="item.index">
                <v-list-item-content @click="addBuilding(item.BUD)">
                  {{ item.BUD }}
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
            </template>
          </v-virtual-scroll>
        </v-card>
        <van-col class="itserve-center" span="24">
          <itserve-route
              v-if="showBtn"
              :buttonName="$t('buttons.addBuilding')"
              route-name="AddApartment"
              type="wide"
          ></itserve-route>
        </van-col>
      </van-form>
    </van-col>
  </van-row>
</template>
<script>
import Form from "vant/lib/form/index";
import InfoMessage from "@/components/content/InfoMessage";
import "vue-awesome/icons/plus";
import "vue-awesome/icons/phone";
// import {VCard, VDivider, VListItem, VListItemContent, VTextField, VVirtualScroll} from "vuetify/lib";
import ItserveRoute from "@/components/content/ItserveRoute";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "AddBuilding",
  data: () => ({
    building: "",
    apartment: "",
    listBuilding: false,
    showBtn: false,
  }),

  watch: {
    building() {
      this.buildsArr();

      let arr = this.BUILDS;

      if (arr !== undefined) {
        if (this.building !== "") {
          this.listBuilding = true;
          this.showBtn = true;
          let data = {
            building: this.building,
          };

          this.setInfoAddedAddress(data);
        }
      }
    },
  },

  computed: {
    ...mapGetters("ManualAddress", [
      "BUILDS",
      "APARTMENTS",
      "SELECTED_CITY",
      "SELECTED_STREET",
    ]),
  },

  components: {
    ItserveRoute,
    InfoMessage,
    VanForm: Form,
    // VVirtualScroll,
    // VListItemContent,
    // VListItem,
    // VDivider,
    // VTextField,
    // VCard,
  },

  methods: {
    ...mapActions("ManualAddress", [
      "getBuildsArr",
      "getApartmentsCheck",
      "getAccountsArr",
    ]),
    ...mapMutations("ManualAddress", ["setInfoAddedAddress"]),

    buildsArr() {
      // let street = this.SELECTED_STREET.split(" ");

      // if (street[2] === undefined) {
      //   street = street[1];
      // } else if (street[3] !== undefined) {
      //   street = street[1] + " " + street[2] + " " + street[3];
      // } else {
      //   street = street[1] + " " + street[2];
      // }

      let data = {
        city: this.SELECTED_CITY,
        street: this.SELECTED_STREET,
        building: this.building,
      };

      this.getBuildsArr(data);
    },

    accountsArr() {
      // let street = this.SELECTED_STREET.split(" ");
      //
      // if (street[2] === undefined) {
      //   street = street[1];
      // } else if (street[3] !== undefined) {
      //   street = street[1] + " " + street[2] + " " + street[3];
      // } else {
      //   street = street[1] + " " + street[2];
      // }
      let data = {
        city: this.SELECTED_CITY,
        street: this.SELECTED_STREET,
        building: this.building,
        apartment: "",
      };
      this.getAccountsArr(data);
    },

    checkedApart() {
      let data = {
        city: this.SELECTED_CITY,
        street: this.SELECTED_STREET,
        building: this.building,
      };
      this.getApartmentsCheck(data);
    },

    addBuilding(building) {
      this.building = building;
      this.listBuilding = false;
      this.accountsArr();
    },
  },
};
</script>
<style scoped>
@import "../../../node_modules/vant/lib/button/index.css";
@import "../../../node_modules/vant/lib/dialog/index.css";
@import "../../../node_modules/vant/lib/overlay/index.css";
@import "../../../node_modules/vant/lib/popup/index.css";
</style>

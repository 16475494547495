<template>
  <van-row
    justify="center"
    class="page-wrapper have-bottom-menu account-list-page"
  >
    <van-col span="24" class="itserve-center round-shadow-btn">
      <div class="place-content-center">
        <info-message class="apply-loader">
          {{ $t("accountList.pushAtAccount") }}
        </info-message>
      </div>
      <itserve-route
        route-name="AddAccount"
        :buttonName="$t('buttons.addAccount')"
      ></itserve-route>
    </van-col>
    <van-col span="24" class="itserve-center bottom-content-grow">
      <template v-if="Object.keys(accounts).length">
        <template v-for="account in accounts">
          <account
            v-bind:key="account.id"
            :account="account"
            :link="false"
            @click.native="deleteAccount(account.accountNumber)"
            class="account-list-item"
          />
        </template>
      </template>
      <template v-else>
        <info-message class="place-content-center">
          {{ $t("accountList.notFound") }}
        </info-message>
      </template>
    </van-col>
  </van-row>
</template>
<script>
import Dialog from "vant/lib/dialog/index";
import Account from "@/components/helpers/Account";
import ItserveRoute from "@/components/content/ItserveRoute";
import InfoMessage from "@/components/content/InfoMessage";
import "vue-awesome/icons/plus";
export default {
  name: "AccountsList",
  components: {
    InfoMessage,
    ItserveRoute,
    Account,
  },
  computed: {
    accounts() {
      return this.$store.state.Auth.accounts;
    },
    routingHistory() {
      return this.$store.state.BackButton.routingHistory;
    },
  },
  created() {
    this.$store.dispatch("Auth/getUserAccounts");
  },
  methods: {
    deleteAccountFromRoutingHistory(accountNumber) {
      let newRouter = this.routingHistory.filter((route) => {
        if (route.params === null) {
          return route;
        } else {
          if (Object.prototype.hasOwnProperty.call(route.params, "account")) {
            if (route.params.account === accountNumber) {
              return false;
            } else {
              return route;
            }
          }
        }
      });
      this.$store.commit("BackButton/SET_ROUTER_HISTORY", newRouter);
    },
    deleteAccount(accountNumber) {
      Dialog.confirm({
        title: this.$t("accountList.dialogDeleteMessage") + accountNumber,
        message: this.$t("accountList.dialogQuestion"),
        cancelButtonText: this.$t("accountList.dialogCancel"),
        confirmButtonText: this.$t("accountList.dialogConfirm"),
      })
        .then(async () => {
          await this.$store.dispatch("Auth/deleteUserAccount", accountNumber);
          this.deleteAccountFromRoutingHistory(accountNumber);
        })
        .catch(() => {});
    },
  },
};
</script>
<style>
.account-list-page .info-content {
  text-align: center !important;
}
</style>
<style scoped>
@import "../../../node_modules/vant/lib/cell/index.css";
@import "../../../node_modules/vant/lib/dialog/index.css";
@import "../../../node_modules/vant/lib/overlay/index.css";
@import "../../../node_modules/vant/lib/popup/index.css";
.account-list-item {
  border-bottom: 1px solid #777777;
}
.account-list-item:not(:first-child) {
  margin-top: 25px;
}
</style>

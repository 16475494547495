<template>
  <van-row class="page-wrapper security-page" justify="center">
    <van-col span="24" class="flex-fix round-shadow-btn">
      <info-message class="apply-loader place-content-center">
        {{ $t("security.message") }}
      </info-message>
    </van-col>
    <van-col span="24" class="flex-display-fix">
      <van-row class="mt-25 apply-loader">
        <van-col span="8" class="itserve-right security-label">
          {{ $t("security.off") }}
        </van-col>
        <van-col span="8" class="itserve-center">
          <van-switch
            v-model="autologin"
            active-color="#4CD964"
            inactive-color="#777777"
          />
        </van-col>
        <van-col span="8" class="itserve-left security-label">
          {{ $t("security.on") }}
        </van-col>
      </van-row>
    </van-col>
  </van-row>
</template>

<script>
import Switch from "vant/lib/switch/index";
import InfoMessage from "@/components/content/InfoMessage";
export default {
  name: "Security",
  computed: {
    autologin: {
      get() {
        return this.$store.state.Security.autologin;
      },
      set(autologin) {
        this.$store.commit("Security/SET_AUTOLOGIN", autologin);
        this.nativeSendEvent(autologin ? "enableSecure" : "disableSecure", {
          secureEnabled: autologin,
        });
      },
    },
  },
  components: {
    InfoMessage,
    VanSwitch: Switch,
  },
};
</script>
<style>
.security-page .info-content {
  text-align: center !important;
}
</style>
<style scoped>
@import "../../../node_modules/vant/lib/switch/index.css";
.security-label {
  padding-top: 10px;
  color: #2e73b4;
  font-size: 18px !important;
}
.flex-fix {
  background-color: #fff;
  padding-bottom: 25px;
}
</style>

<template>
  <van-row class="page-wrapper add-account-page" justify="center">
    <van-col
        span="24"
        class="itserve-center round-shadow-btn flex-display-fix mb-25"
    >
      <div class="place-content-center">
        <info-message class="apply-loader add-account-page-message">
          {{ $t("addAccount.street") }}
        </info-message>
      </div>
      <div class="place-content-center">
        <info-message class="apply-loader add-account-page-message">{{
            $t("addAccount.wait")
          }}
        </info-message>
      </div>
      <van-form>
        <v-text-field
            :placeholder="$t('addAccount.inputStreet')"
            autocomplete="off"
            v-model="street"
            class="mt-10"
        ></v-text-field>
        <v-card v-if="listStreet">
          <v-virtual-scroll :items="STREETS" height="200" item-height="64">
            <template v-slot:default="{ item }">
              <v-list-item :key="item.index">
                <v-list-item-content
                    @click="addStreet(item.NVUL)"
                >
                  {{item.NVUL }}
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
            </template>
          </v-virtual-scroll>
        </v-card>
        <van-col class="itserve-center" span="24">
          <itserve-route
              v-if="showBtn"
              :buttonName="$t('buttons.addStreet')"
              route-name="AddBuilding"
              type="wide"
          ></itserve-route>
        </van-col>
      </van-form>
    </van-col>
  </van-row>
</template>
<script>
import Form from "vant/lib/form/index";
import InfoMessage from "@/components/content/InfoMessage";
import "vue-awesome/icons/plus";
import "vue-awesome/icons/phone";
// import {VCard, VDivider, VListItem, VListItemContent, VTextField, VVirtualScroll} from "vuetify/lib";
import ItserveRoute from "@/components/content/ItserveRoute";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "AddStreet",

  data: () => ({
    street: "",
    street_typ: "",
    listStreet: false,
    showBtn: false,
  }),

  watch: {
    street() {
      this.listStreet = true;
      this.streetsArr();

      let arr = this.STREETS;

      if (arr !== undefined) {
        for (let i = 0; i < arr.length; i++) {
          let streetName = arr[i].NVUL;

          if (streetName === this.street) {
            this.listStreet = false;
            this.showBtn = true;
            let data = {
              street: this.street,
            };
            this.setInfoAddedAddress(data);
          }
        }
      }
    },
  },

  computed: {
    ...mapGetters("ManualAddress", ["STREETS", "SELECTED_CITY"]),
  },

  components: {
    ItserveRoute,
    InfoMessage,
    VanForm: Form,
    // VVirtualScroll,
    // VListItemContent,
    // VListItem,
    // VDivider,
    // VCard,
    // VTextField,
  },

  methods: {
    ...mapActions("ManualAddress", ["getStreetsArr"]),
    ...mapMutations("ManualAddress", ["setInfoAddedAddress"]),

    streetsArr() {
      let data = {
        city: this.SELECTED_CITY,
        street: this.street,
      };
      this.getStreetsArr(data);
    },

    addStreet(street) {
      this.street = street;
      this.listStreet = false;
    },
  },
};
</script>
<style scoped>
@import "../../../node_modules/vant/lib/button/index.css";
@import "../../../node_modules/vant/lib/dialog/index.css";
@import "../../../node_modules/vant/lib/overlay/index.css";
@import "../../../node_modules/vant/lib/popup/index.css";
</style>

import { render, staticRenderFns } from "./IndicationsHistory.vue?vue&type=template&id=651274bc&scoped=true&"
import script from "./IndicationsHistory.vue?vue&type=script&lang=js&"
export * from "./IndicationsHistory.vue?vue&type=script&lang=js&"
import style0 from "./IndicationsHistory.vue?vue&type=style&index=0&id=651274bc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "651274bc",
  null
  
)

export default component.exports
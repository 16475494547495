<template>
  <div class="itserve-input lang-picker">
    <div class="input-wrapper">
      <div class="itserve-picker apply-loader" @click="showPicker = true">
        <h5 class="language-picker-title">
          {{ $t("components.picker.lang") }}
        </h5>
        <p>{{ $t("components.picker." + $i18n.locale) }}</p>
      </div>
      <van-popup v-model="showPicker" round position="bottom">
        <van-picker
          ref="itserveLanguagePicker"
          show-toolbar
          :columns="locales"
          :confirm-button-text="$t('components.picker.confirmBtn')"
          :cancel-button-text="$t('components.picker.cancelBtn')"
          @cancel="showPicker = false"
          @confirm="selectLanguage"
          :default-index="selectedLocale"
        />
      </van-popup>
    </div>
  </div>
</template>
<script>
import Picker from "vant/lib/picker/index";
import Popup from "vant/lib/popup/index";

export default {
  name: "ItserveLanguagePicker",
  components: { VanPicker: Picker, VanPopup: Popup },
  props: {
    value: String,
  },
  data() {
    return {
      showPicker: false,
      selectedLocale: null,
      locales: [],
      content: this.value,
    };
  },
  computed: {
    locale() {
      return this.$store.state.Auth.locale;
    },
  },
  watch: {
    locale() {
      this.prepareLocales();
    },
  },
  created() {
    this.prepareLocales();
  },
  methods: {
    selectLanguage(lang, index) {
      this.selectedLocale = index;
      this.showPicker = false;
      this.$emit("input", lang.value);
      this.prepareLocales();
    },
    prepareLocales() {
      this.locales = [];
      let locales = process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(",");
      for (let i = 0; i < locales.length; i++) {
        if (this.content === locales[i] && this.selectedLocale === null) {
          this.selectedLocale = i;
        }
        this.locales.push({
          text: this.$t("components.picker." + locales[i]),
          value: locales[i],
        });
      }
    },
  },
};
</script>
<style scoped>
@import "../../../node_modules/vant/lib/picker/index.css";
@import "../../../node_modules/vant/lib/field/index.css";
@import "../../../node_modules/vant/lib/popup/index.css";

.lang-picker {
  margin: 10px;
}

.itserve-picker {
  border-bottom: 1px solid #918dab;
  margin: 10px 0 10px 0px;
  padding-left: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
  position: relative;
  max-width: 337px;
  box-sizing: border-box;
  width: 100%;
  display: inline-block;
  text-align: left;
}

.itserve-picker h5 {
  color: #2e73b4;
  font-size: 20px;
  font-weight: normal;
}
.itserve-picker p {
  color: #918dab;
  font-size: 18px;
  padding-top: 3px !important;
}

.itserve-picker h5,
.itserve-picker p {
  margin: 0;
}
.picker-icon {
  position: absolute;
  bottom: 3px;
  right: 10px;
  color: #918dab;
}
</style>

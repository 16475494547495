<template>
  <van-row class="support-menu">
    <van-col span="8" class="nav-wrapper">
      <router-link :to="{ name: 'WriteTicket' }"
        ><div class="icon-box"><edit2-icon size="20" /></div>
        <br /><span class="link-text">{{
          $t("components.supportBottomMenu.writeTicket")
        }}</span></router-link
      >
    </van-col>
    <van-col span="8" class="nav-wrapper">
      <router-link :to="{ name: 'Contacts' }"
        ><div class="icon-box"><phone-icon size="20" /></div>
        <br /><span class="link-text">{{
          $t("components.supportBottomMenu.contacts")
        }}</span></router-link
      >
    </van-col>
    <van-col span="8" class="nav-wrapper">
      <router-link :to="{ name: 'SupportPage' }">
        <div class="icon-box"><list-icon size="20" /></div>
        <br /><span class="link-text">{{
          $t("components.supportBottomMenu.supPage")
        }}</span></router-link
      >
    </van-col>
  </van-row>
</template>
<script>
import PhoneIcon from "vue-feather-icons/icons/PhoneIcon";
import Edit2Icon from "vue-feather-icons/icons/Edit2Icon";
import ListIcon from "vue-feather-icons/icons/LinkIcon";
export default {
  components: {
    PhoneIcon,
    Edit2Icon,
    ListIcon,
  },
  name: "SupportBottomMenu",
};
</script>
<style scoped>
.nav-wrapper .router-link-exact-active .link-text {
  color: #2e73b4;
}
.nav-wrapper .router-link-exact-active .icon-box {
  background-color: #2e73b4;
}
.nav-wrapper {
  text-align: center;
  font-size: 12px;
}
.link-text {
  color: #918dab;
}
.icon-box {
  margin-top: 7px;
  margin-bottom: 3px;
  background-color: #777777;
  color: #fff;
  width: 35px;
  height: 35px;
  border-radius: 30px;
  line-height: 46px;
  display: inline-block;
}
@media (max-width: 340px) {
  .link-text {
    display: none;
  }
  .icon-box {
    margin-top: 15px;
  }
}
</style>

import axios from "axios";
// import api from "@/plugins/api/EntryPoint";

export const state = {
    cities: [],
    streets: [],
    builds: [],
    apartments: [],
    accounts: [],
    infoAddedAddress: {
        city: "",
        street: "",
        street_typ: "",
        building: "",
        apartment: "",
    },

};

export const getters = {
    CITY(state) {
        return state.city;
    },
    CITIES(state) {
        return state.cities;
    },
    STREETS(state) {
        return state.streets;
    },
    BUILDS(state) {
        return state.builds;
    },
    APARTMENTS(state) {
        return state.apartments;
    },
    ACCOUNTS(state) {
        return state.accounts;
    },
    SELECTED_CITY(state) {
        return state.infoAddedAddress.city;
    },
    SELECTED_STREET(state) {
        return state.infoAddedAddress.street;
    },
    SELECTED_STREET_TYP(state) {
        return state.infoAddedAddress.street_typ;
    },
    SELECTED_BUILDING(state) {
        return state.infoAddedAddress.building;
    },
    SELECTED_APARTMENT(state) {
        return state.infoAddedAddress.apartment;
    },
};

export const actions = {
    getCitiesArr(ctx, data) {
        return axios(process.env.VUE_APP_API_ENDPOINT_URL + `accounts/city-search?city=${data.city}`, {
            method: 'GET',
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "*",
            },
        })
            .then((response) => {
                ctx.commit('setCities', response.data.city)
            }).catch((error)=> {
                console.log(error)
            })
    },

    getStreetsArr(ctx,data) {
        return axios(
            process.env.VUE_APP_API_ENDPOINT_URL + `accounts/street-search?street=${data.street}&city=${data.city}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Headers": "*",
                },
            }).then((response) => {
            ctx.commit("setStreets", response.data.street);
        }).catch((error) => {
            console.log(error);
        });
    },

    getBuildsArr(ctx, data) {
        return axios(
            process.env.VUE_APP_API_ENDPOINT_URL + `accounts/building-search?
          street=${data.street}
          &city=${data.city}
          &building=${data.building}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Headers": "*",
                },
            }).then((response) => {
            ctx.commit("setBuilds", response.data.building);
        }).catch((error) => {
            console.log(error);
        });
    },

    getApartmentsCheck(ctx, data) {
        return axios(
            process.env.VUE_APP_API_ENDPOINT_URL + `accounts/apartment-check?
          street=${data.street}
          &city=${data.city}
          &building=${data.building}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Headers": "*",
                },
            }).then((response) => {
            ctx.commit("setApartments", response.data.apartment);
        }).catch((error) => {
            console.log(error);
        });
    },

    getApartmentsArr(ctx, data) {
        return axios(
            process.env.VUE_APP_API_ENDPOINT_URL + `accounts/apartment-search?
          street=${data.street}
          &city=${data.city}
          &building=${data.building}
          &apartment=${data.apartment}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Headers": "*",
                },
            }).then((response) => {
            ctx.commit("setApartments", response.data.apartment);
        }).catch((error) => {
            console.log(error);
        });
    },

    async getAccountsArr(ctx, data) {
        return axios(
            process.env.VUE_APP_API_ENDPOINT_URL + `accounts/ls-search?
          street=${data.street}
          &city=${data.city}
          &building=${data.building}
          &apartment=${data.apartment}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }).then((response) => {
            ctx.commit("setAccounts", response.data);
        }).catch((error) => {
            console.log(error);
        });
    },

};

export const mutations = {
    setCity(state, value) {
        state.city = value;
    },
    setCities(state, value) {
        state.cities = value;
    },
    setStreets(state, value) {
        state.streets = value;
    },
    setBuilds(state, value) {
        state.builds = value;
    },
    setApartments(state, value) {
        state.apartments = value;
    },
    setAccounts(state, value) {
        state.accounts = value;
    },




    setInfoAddedAddress(state, data) {
        if (data.all) {
            state.infoAddedAddress.city = data.city;
            state.infoAddedAddress.street = data.street;
            state.infoAddedAddress.building = data.building;
            state.infoAddedAddress.geon_id = data.geon_id;
            state.infoAddedAddress.corpus = data.corpus;
            state.infoAddedAddress.city_id = data.city_id;
            state.infoAddedAddress.apartment = data.apartment;
        } else if (data.street) {
            state.infoAddedAddress.street = data.street;
        } else if (data.building) {
            state.infoAddedAddress.building = data.building;
            state.infoAddedAddress.geon_id = data.geon_id;
            state.infoAddedAddress.corpus = data.corpus;
        } else if (data.corpus) {
            state.infoAddedAddress.corpus = data.corpus;
        } else if (data.apartment) {
            state.infoAddedAddress.apartment = data.apartment;
        } else if (data.city) {
            state.infoAddedAddress.city = data.city;
        }
    },

    setShowGeo(state, value) {
        state.coordinates.status = true;
        state.coordinates.lat = value.latitude;
        state.coordinates.lon = value.longitude;
    },

    setConfirmed(state, value) {
        state.confirmed = value;
    },

    clearAddress(state) {
        (state.infoAddedAddress = {
            city: "",
            street: "",
            building: "",
            corpus: "",
            apartment: "",
            geon_id: "",
            city_id: "",
        }),
            (state.key = 1);
        state.coordinates.status = false;
    },
};

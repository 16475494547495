<template>
  <div class="settings-block">
    <div class="place-content-center">
      <div class="icon-container">
        <div class="icon-box apply-loader">
          <slot name="icon"></slot>
        </div>
      </div>
      <div class="text-container">
        <h5 class="settings-block-title apply-loader">
          <slot name="title"></slot>
        </h5>
        <p class="settings-block-content apply-loader">
          <slot name="content"></slot>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SettingsBlock",
};
</script>

<style scoped>
.settings-block-title {
  color: #0b0b0b;
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-weight: normal;
  max-width: 95%;
  margin-bottom: 4px;
}
.settings-block-content {
  font-size: 18px;
  color: #918dab;
  margin: 0;
  padding: 5px 0 0 0;
  max-width: 95%;
}
.settings-block {
  width: 100%;
  border-bottom: 1px solid #918dab;
  padding-bottom: 14px;
  padding-top: 10px;
  overflow: hidden;
  padding-left: 10px;
  background-color: #ffffff;
}
.icon-box {
  text-align: center;
  margin-left: 15px;
  margin-top: 7px;
  background-color: #1aad62;
  color: #fff;
  width: 41px;
  height: 41px;
  border-radius: 30px;
  line-height: 52px;
  display: inline-block;
}
.icon-container,
.text-container {
  position: relative;
  float: left;
}
@media (max-width: 340px) {
  .icon-container {
    width: 25%;
  }
  .text-container {
    width: 74%;
  }
}
@media (min-width: 340px) {
  .icon-container {
    width: 20%;
  }
  .text-container {
    width: 79%;
  }
}

.text-container {
  color: #0b0b0b;
  text-align: left;
  font-size: 16px;
  padding-top: 5px;
}
</style>

<template>
  <van-col class="itserve-center" span="24">
    <h5 @click="switchFlashlight" class="apply-loader">
      <template v-if="flashlightEnabled">
        {{ $t("regionalEnergy.sendIndications.disableFlashlight") }}
      </template>
      <template v-else>
        {{ $t("regionalEnergy.sendIndications.enableFlashlight") }}
      </template>
    </h5>

    <button class="flashlight-button" @click="switchFlashlight">
      <template v-if="flashlightEnabled">
        <img src="@/assets/images/flashlight-on.png" alt="flashlight-on" />
      </template>
      <template v-else>
        <img src="@/assets/images/flashlight-off.png" alt="flashlight-off" />
      </template>
    </button>
  </van-col>
</template>

<script>
export default {
  name: "Flashlight",
  computed: {
    flashlightEnabled() {
      return this.$store.state.Flashlight.flashlightEnabled;
    },
  },
  methods: {
    /**
     * This methods use app-bridge.js to send events into vue-native
     */
    async switchFlashlight() {
      if (this.flashlightEnabled) {
        await this.$store.dispatch("Flashlight/disableFlashlight");
      } else {
        await this.$store.dispatch("Flashlight/enableFlashlight");
      }
    },
  },
};
</script>

<style scoped>
.flashlight-button {
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #ffffff;
  border: none;
  width: 75px;
  height: 75px;
  padding: 0;
}
h5 {
  color: #0b0b0b;
  padding: 0;
  text-align: center;
  font-size: 18px;
  margin: 0;
}
</style>

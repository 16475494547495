<template>
  <div class="check_box_ios">
    <div class="side">
      <div>
        <span>{{ $t("settings.lightTheme") }}</span>
      </div>
      <div>
        <input
          type="checkbox"
          class="checkbox"
          id="checkbox"
          v-model="checked"
          @change="changeTheme"
        />
        <label for="checkbox" class="ios-switch"></label>
      </div>
      <div>
        <span>{{ $t("settings.darkTheme") }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckBoxIOS",
  computed: {
    theme: {
      get() {
        return this.$store.state.Auth.appTheme;
      },
    },
  },
  data: () => ({
    checked: false,
  }),
  mounted() {
    if (this.theme === "dark") {
      this.checked = true;
    }
  },
  methods: {
    changeTheme() {
      if (this.checked) {
        const themeMode = "dark";
        this.$store.commit("Auth/SET_APP_THEME", themeMode);

        this.nativeSendEvent("setThemeMode", themeMode);
      } else {
        const themeMode = "light";
        this.$store.commit("Auth/SET_APP_THEME", themeMode);

        this.nativeSendEvent("setThemeMode", themeMode);
      }
    },
  },
};
</script>

<style scoped>
.decoration {
  text-decoration: underline;
}

.check_box_ios {
  max-width: 337px;
  margin: 30px auto;
}

.side {
  padding: 0px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
label {
  cursor: pointer;
}

.checkbox {
  vertical-align: top;
  margin: 0 3px 0 0;
  width: 17px;
  height: 17px;
}
.checkbox:not(checked) {
  position: absolute;
  opacity: 0;
}
.checkbox:not(checked) + label.ios-switch {
  position: relative;
  padding: 0 0 0 60px;
  display: block;
  height: 22px;
}
.checkbox:not(checked) + label.ios-switch:before {
  content: "";
  position: absolute;
  top: -2px;
  left: 0;
  width: 50px;
  height: 26px;
  border-radius: 13px;
  border-color: #cdd1da;
  box-shadow: #cdd1da 0px 0px 0px 0px inset;
  transition: border 0.4s, box-shadow 0.4s;
  background-color: #ebecec;
}
.checkbox:not(checked) + label.ios-switch:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 2px;
  width: 22px;
  height: 22px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  transition: all 0.2s;
}
.checkbox:checked + label.ios-switch:before {
  box-shadow: #fbc02d 0px 0px 0px 16px inset;
  transition: border 0.4s, box-shadow 0.4s, background-color 1.2s;
  background-color: #fbc02d;
  border-color: #fbc02d;
}
.checkbox:checked + label.ios-switch:after {
  left: 26px;
}
</style>

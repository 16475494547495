<template>
  <van-row justify="center" class="page-wrapper have-bottom-menu">
    <van-col
      span="24"
      class="itserve-center place-content-center flex-display-fix"
    >
      <kharkov-obl-energo />
    </van-col>
  </van-row>
</template>
<script>
import KharkovOblEnergo from "@/components/contacts/KharkovOblEnergo";
export default {
  name: "ContactRegionalEnergy",
  components: { KharkovOblEnergo },
};
</script>

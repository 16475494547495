<template>
  <van-row class="page-wrapper" justify="center">
    <van-col class="itserve-center round-shadow-btn" span="24">
      <div class="place-content-center">
        <info-message class="apply-loader verification-message">
          {{ $t("verification.verificationInfo") }}
        </info-message>
      </div>
    </van-col>
    <van-col class="itserve-center" span="24">
      <itserve-route
        route-name="CounterVerification"
        :params="$route.params"
        :buttonName="$t('verification.verificationByCounter')"
        type="wide"
        class="tall-btn mt-15"
      ></itserve-route>

      <itserve-route
        route-name="SurnameVerification"
        :params="$route.params"
        :buttonName="$t('verification.verificationBySurname')"
        type="wide"
        class="tall-btn mt-15"
      ></itserve-route>

      <itserve-route
        route-name="ContractVerification"
        :params="$route.params"
        :buttonName="$t('verification.verificationByDate')"
        type="wide"
        class="tall-btn mt-15 mb-25"
      ></itserve-route>
    </van-col>
  </van-row>
</template>

<script>
import InfoMessage from "@/components/content/InfoMessage";
import ItserveRoute from "@/components/content/ItserveRoute";
import "vue-awesome/icons/barcode";
import "vue-awesome/icons/user";
import "vue-awesome/icons/calendar-alt";
export default {
  name: "Verification",
  components: { ItserveRoute, InfoMessage },
};
</script>

<style>
.verification-message .info-content {
  text-align: center !important;
}
</style>

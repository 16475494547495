<template>
  <van-row justify="center" class="page-wrapper">
    <van-col span="24" class="mt-25 place-content-center flex-display-fix">
      <h5 class="apply-loader contacts-title">
        {{ $t("contacts.energosbytTitle") }}
      </h5>
      <info-message class="round-shadow apply-loader contacts-list">
        <van-cell
          :title="$t('contacts.callCenter')"
          value="+38 (0412) 55-92-72"
          @click="makeACall('+380412559272')"
        />
        <van-cell
          :title="$t('contacts.address')"
          :value="$t('contacts.energosbytAddress')"
        />
        <van-cell
          :title="$t('contacts.email') + ': kanc@ztoek.com.ua'"
          @click="sendMail('kanc@ztoek.com.ua')"
        />
      </info-message>

      <h5 class="apply-loader contacts-title">{{ $t("contacts.schedule") }}</h5>
      <info-message class="round-shadow apply-loader contacts-list ctx-message">
        <van-cell :title="$t('contacts.monThu')" value="8:00-17:00" />
        <van-cell :title="$t('contacts.fri')" value="8:00-16:00" />
        <van-cell :title="$t('contacts.timeOff')" value="12:00-13:48" />
      </info-message>

      <kharkov-obl-energo />
    </van-col>
  </van-row>
</template>
<script>
import { Cell } from "vant";
import KharkovOblEnergo from "@/components/contacts/KharkovOblEnergo";
import InfoMessage from "@/components/content/InfoMessage";
export default {
  name: "Contacts",
  components: { InfoMessage, KharkovOblEnergo, VanCell: Cell },
  methods: {
    makeACall(number) {
      this.nativeSendEvent("tel", { number });
    },
    sendMail(email) {
      this.nativeSendEvent("email", {
        to: email,
        subject: this.$t("contacts.subject"),
        body: "",
      });
    },
  },
};
</script>
<style scoped>
@import "../../../../node_modules/vant/lib/cell/index.css";
h5 {
  margin: 0;
  text-align: center;
  font-size: 18px;
  color: #0b0b0b;
}
</style>

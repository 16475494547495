<template>
  <van-row>
    <van-col
      class="itserve-center history-search-page round-shadow-btn"
      span="24"
    >
      <van-row>
        <van-col span="24" class="date-block apply-loader">
          {{ $t("components.searchEntry.setIndicationFrom") }}
        </van-col>
        <van-col span="24">
          <CustomDatePicker v-model="dates.dateFrom" class="apply-loader" />
        </van-col>
        <van-col span="24" class="date-block apply-loader">
          {{ $t("components.paymentHistorySearchForm.setIndicationsTo") }}
        </van-col>
        <van-col span="24">
          <CustomDatePicker
            v-model="dates.dateTo"
            :picker-title="$t('components.searchEntry.setIndicationFrom')"
            format="YYYY-MM-DD"
            class="apply-loader"
          />
        </van-col>
        <van-col span="24">
          <template
            v-if="
              dates.dateFrom !== null && dates.dateTo !== null && !pickerActive
            "
          >
            <itserve-button
              :value="$t('components.paymentHistorySearchForm.find')"
              type="primary"
              @click="openSearchPage"
              class="mt-15 apply-loader"
            ></itserve-button>
          </template>
        </van-col>
      </van-row>
    </van-col>
  </van-row>
</template>

<script>
import ItserveButton from "@/components/forms/ItserveButton";
import "vue-awesome/icons/calendar-alt";
import "vue-awesome/icons/eye";
import CustomDatePicker from "@/components/helpers/CustomDatePicker";

export default {
  name: "IndicationsHistory",
  components: {
    CustomDatePicker,
    ItserveButton,
  },
  computed: {
    paymentHistory() {
      return this.$store.state.PaymentHistory.paymentHistory;
    },
  },
  data() {
    return {
      pickerType: null,
      pickerActive: false,
      chartData: null,
      maxDate: new Date(),
      dates: {
        dateFrom: null,
        dateTo: null,
      },
    };
  },
  created() {
    this.dates.dateFrom = this.getDate(true);
    this.dates.dateTo = this.getDate();
    this.getHistory();
  },
  methods: {
    getFilterValues() {
      return {
        ls: this.$route.params.account,
        start_date: this.formatDate(this.dates.dateFrom),
        end_date: this.formatDate(this.dates.dateTo),
      };
    },
    async getHistory() {
      this.chartData = null;
      await this.$store.commit(
        "PaymentHistory/SET_FILTER_VALUES",
        this.getFilterValues()
      );
      await this.$store.dispatch("PaymentHistory/getPaymentHistory");
    },
    getDate(monthBack = false) {
      let date = new Date();
      if (monthBack) {
        date.setMonth(
          date.getMonth() - process.env.VUE_APP_HISTORY_SEARCH_MONTHBACK
        );
        return date;
      } else {
        return date;
      }
    },
    formatDate(date) {
      let month = date.getMonth() + 1;
      let day = date.getDate();
      return `${date.getFullYear()}-${month < 10 ? "0" + month : month}-${
        day < 10 ? "0" + day : day
      }`;
    },
    async openSearchPage() {
      let dateFrom = new Date(this.dates.dateFrom).getTime();
      let dateTo = new Date(this.dates.dateTo).getTime();
      if (dateTo < dateFrom) {
        this.dates.dateFrom = [
          this.dates.dateTo,
          (this.dates.dateTo = this.dates.dateFrom),
        ][0];
      }
      await this.getHistory();
      await this.$router.push({
        name: "PaymentHistoryResults",
        params: this.getFilterValues(),
      });
    },
  },
};
</script>

<style scoped>
@import "../../../node_modules/vant/lib/picker/index.css";
@import "../../../node_modules/vant/lib/cell/index.css";
@import "../../../node_modules/vant/lib/popup/index.css";

.absolute-title {
  position: absolute;
  z-index: 9999;
  width: 100%;
  top: 44px;
}
.date-block {
  padding: 10px;
  min-height: 39px;
  text-align: center;
  color: #918dab;
}
.history-search-page {
  padding-left: 12px;
  padding-right: 12px;
}
.green-btn {
  background-color: #1aad62;
}
</style>
<style>
.changed-buttons .van-picker__toolbar {
  margin-bottom: 64px;
}

.van-picker__confirm {
  background-color: #2e73b4;
  color: #fff;
  margin-top: 10px;
  margin-right: 15px;
  height: 30px;
  margin-left: 5px;
}
.van-picker__cancel {
  background-color: #2e73b4;
  color: #fff;
  margin-top: 10px;
  margin-left: 15px;
  height: 30px;
  margin-right: 5px;
}
</style>

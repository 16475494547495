<template>
  <van-row class="page-wrapper send-indications-photo" justify="center">
    <van-col span="24" class="round-shadow-btn flex-fix">
      <info-message
        class="
          itserve-center
          apply-loader
          place-content-center
          mt-25
          indications-send-text
        "
      >
        {{ $t("sendPhotoPageMainMenu.sendPhotoPageDesc") }}
      </info-message>
    </van-col>
    <send-counter-photo />
  </van-row>
</template>
<script>
import InfoMessage from "@/components/content/InfoMessage";
import SendCounterPhoto from "@/components/indications/SendCounterPhoto";

export default {
  name: "SendIndicationsPhoto",
  components: {
    SendCounterPhoto,
    InfoMessage,
  },
  computed: {
    appTheme() {
      return this.$store.state.Auth.appTheme;
    },
  },
};
</script>

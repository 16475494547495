<template>
  <van-row class="page-wrapper" justify="center">
    <van-col class="itserve-center round-shadow-btn" span="24">
      <van-row>
        <van-col span="24" class="date-block apply-loader mt-25 mb-25">
          {{ $t("contractVerification.counterPlaceholder") }}
        </van-col>
      </van-row>
      <label
        class="itserve-label"
        :for="'_itserve-input-cdate'"
        :class="
          formErrors !== null &&
          Object.keys(formErrors.errors).indexOf('cdate') >= 0
            ? `error-label label-show-up show-label`
            : `standard-label`
        "
        >{{
          formErrors !== null &&
          Object.keys(formErrors.errors).indexOf("cdate") >= 0
            ? formErrors.errors["cdate"][0]
            : $t("contractVerification.counterPlaceholder")
        }}</label
      >
      <CustomDatePicker
        v-model="verification.sourceDate"
        class="apply-loader verification-input"
        @input="setDate(verification.sourceDate)"
      />
    </van-col>
    <van-col span="24" class="itserve-center">
      <itserve-button
        type="primary"
        :value="$t('verification.verifyButton')"
        class="itserve-button-wide verification-button mt-15 apply-loader"
        @click="verificate"
      ></itserve-button>
    </van-col>
    <simple-dialog
      v-model="showDialog"
      :title="dialogMessage"
      :success="success"
      @change="goToAccount"
    />
  </van-row>
</template>

<script>
import ItserveButton from "@/components/forms/ItserveButton";
import CustomDatePicker from "@/components/helpers/CustomDatePicker";
import sendVerificationRequest from "@/mixins/verification/sendVerificationRequest";
import "vue-awesome/icons/check";
import SimpleDialog from "@/components/content/SimpleDialog";
export default {
  name: "ContractVerification",
  components: {
    SimpleDialog,
    ItserveButton,
    CustomDatePicker,
  },
  mixins: [sendVerificationRequest],
  computed: {
    formErrors() {
      return this.$store.state.Api.errors;
    },
  },
  data() {
    return {
      minDate: new Date(1990, 0, 1),
      selectedDate: new Date(),
      showDatePicker: false,
      verification: {
        ls: this.$route.params.account,
        sourceDate: new Date(),
        cdate: null,
        type: "contract",
      },
    };
  },
  methods: {
    setDate(selectedDate) {
      console.log("SELECTED DATE CHANGED");
      console.log(selectedDate);
      let month = selectedDate.getMonth() + 1;
      let day = selectedDate.getDate();
      this.verification.cdate = `${day < 10 ? "0" + day : day}.${
        month < 10 ? "0" + month : month
      }.${selectedDate.getFullYear()}`;
      this.switchDatePicker();
    },
    switchDatePicker() {
      this.showDatePicker = !this.showDatePicker;
    },
  },
};
</script>

<style scoped>
@import "../../../../../node_modules/vant/lib/picker/index.css";
@import "../../../../../node_modules/vant/lib/cell/index.css";
@import "../../../../../node_modules/vant/lib/popup/index.css";
@import "../../../../../node_modules/vant/lib/dialog/index.css";
@import "../../../../../node_modules/vant/lib/overlay/index.css";
.absolute-title {
  position: absolute;
  z-index: 9999;
  width: 100%;
  top: 44px;
}
.input-wrapper {
  overflow: hidden;
}
</style>
<style>
.changed-buttons .van-picker__toolbar {
  margin-bottom: 64px;
}

.van-picker__confirm {
  background-color: #2e73b4;
  color: #fff;
  margin-top: 10px;
  margin-right: 15px;
  height: 30px;
  margin-left: 5px;
}
.van-picker__cancel {
  background-color: #2e73b4;
  color: #fff;
  margin-top: 10px;
  margin-left: 15px;
  height: 30px;
  margin-right: 5px;
}
</style>

<template>
  <van-row class="page-wrapper" justify="center">
    <indications-history-search-form />
  </van-row>
</template>
<script>
import IndicationsHistorySearchForm from "@/components/history/IndicationsHistorySearchForm";
export default {
  name: "IndicationsHistory",
  components: { IndicationsHistorySearchForm },
};
</script>
<style scoped>
.page-wrapper {
  background-color: #ffffff;
}
</style>

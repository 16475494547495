<template>
  <van-row>
    <van-col span="24" class="mb-25">
      <datetime
        v-model="content"
        @focus="hideTawk"
        @close="showTawk"
        @input="dateChanged"
        value-zone="Europe/Kiev"
        format="yyyy-MM-dd"
        :max-datetime="maxDate.toISOString()"
        input-class="datepicker-input"
      >
        <template slot="button-cancel">
          {{ $t("components.paymentHistorySearchForm.cancelDate") }}
        </template>
        <template slot="button-confirm">
          {{ $t("components.paymentHistorySearchForm.acceptDate") }}
        </template>
      </datetime>
    </van-col>
  </van-row>
</template>

<script>
import { Datetime } from "vue-datetime";
import { Settings } from "luxon";

export default {
  name: "CustomDatePicker",
  components: { Datetime },
  props: {
    value: [Date],
  },
  computed: {
    language() {
      return this.$store.state.Auth.locale;
    },
  },
  data() {
    return {
      maxDate: new Date(),
      content: this.value ? this.value.toISOString() : "",
    };
  },
  created() {
    Settings.defaultLocale = this.language;
  },
  methods: {
    showTawk() {
      try {
        window.Tawk_API.showWidget();
      } catch (e) {
        console.log("Widget not found");
      }
    },
    hideTawk() {
      try {
        window.Tawk_API.hideWidget();
      } catch (e) {
        console.log("Widget not found");
      }
    },
    dateChanged() {
      console.log(this.content);
      this.$emit("input", new Date(this.content));
    },
  },
};
</script>
<style scoped>
@import "~vue-datetime/dist/vue-datetime.css";
</style>
<style>
.datepicker-input {
  width: 100%;
  outline: 0;
  border-width: 0 0 1px;
  border-color: #777777;
  outline: none;
  font-size: 16px;
  padding: 10px 10px 10px 10px;
  max-width: 337px;
  box-sizing: border-box;
  background-color: #fff;
  text-align: center;
}
</style>

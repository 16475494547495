<template>
  <van-row class="page-wrapper shutdown-page" justify="center">
    <van-col span="24" class="place-content-center round-shadow-btn flex-fix">
      <info-message class="itserve-center apply-loader">
        {{ $t("regionalEnergy.reportShutdown.noElectricMessage") }}
      </info-message>
    </van-col>
    <van-col span="24">
      <template v-if="showStepOne">
        <itserve-dialog
          :cancel-text="$t('regionalEnergy.reportShutdown.no')"
          :confirm-text="$t('regionalEnergy.reportShutdown.yes')"
          :dialog-title="
            $t('regionalEnergy.reportShutdown.additionalQuestions')
          "
          @cancel="saveAutomatesChoice('Нет')"
          @confirm="saveAutomatesChoice('Да')"
        >
          <div class="dialog-content">
            {{ $t("regionalEnergy.reportShutdown.haveYouCheckedMachines") }}
          </div></itserve-dialog
        >
      </template>
      <template v-if="showStepTwo">
        <itserve-dialog
          :cancel-text="$t('regionalEnergy.reportShutdown.no')"
          :confirm-text="$t('regionalEnergy.reportShutdown.yes')"
          :dialog-title="
            $t('regionalEnergy.reportShutdown.additionalQuestions')
          "
          @cancel="saveNeighborsChoice('Нет')"
          @confirm="saveNeighborsChoice('Да')"
        >
          <div class="dialog-content">
            {{ $t("regionalEnergy.reportShutdown.neighborsElectricity") }}
          </div>
          <div>
            <button
              class="
                van-button van-button--default van-button--large
                itserve-app-color
              "
              @click="saveNeighborsChoice('Не знаю')"
            >
              {{ $t("regionalEnergy.reportShutdown.idk") }}
            </button>
          </div>
        </itserve-dialog>
      </template>
      <van-col
        class="itserve-center pb-25 bottom-content-grow flex-display-fix"
        span="24"
      >
        <itserve-input
          v-model="callerfirstname"
          type="text"
          class="apply-loader"
          :placeholder="$t('regionalEnergy.reportShutdown.callerfirstname')"
          name="callerfirstname"
        />
        <itserve-input
          v-model="callerparentname"
          type="text"
          class="apply-loader"
          :placeholder="$t('regionalEnergy.reportShutdown.callerparentname')"
          name="callerparentname"
        />
        <itserve-input
          v-model="callerlastname"
          type="text"
          class="apply-loader"
          :placeholder="$t('regionalEnergy.reportShutdown.callerlastname')"
          name="callerlastname"
        />
        <itserve-textarea
          v-model="contents"
          :placeholder="$t('regionalEnergy.reportShutdown.contents')"
          class="report-shutdown-input apply-loader"
          name="contents"
        />
        <div class="itserve-center">
          <itserve-button
            :value="$t('buttons.reportShutdownApply')"
            type="primary"
            class="apply-loader"
            @click="sendShutDownReport"
          ></itserve-button>
        </div>
      </van-col>
      <simple-dialog
        v-model="showDialog"
        :title="dialogMessage"
        :success="dialogSuccessMessage"
        @change="goBack"
      />
    </van-col>
  </van-row>
</template>
<script>
import ItserveButton from "@/components/forms/ItserveButton";
import ItserveDialog from "@/components/content/ItserveDialog";
import InfoMessage from "@/components/content/InfoMessage";
import ItserveTextarea from "@/components/forms/ItserveTextarea";
import ItserveInput from "@/components/forms/ItserveInput";
import "vue-awesome/icons/paper-plane";
import SimpleDialog from "@/components/content/SimpleDialog";
export default {
  name: "ReportShutdown",
  components: {
    SimpleDialog,
    ItserveInput,
    ItserveTextarea,
    InfoMessage,
    ItserveDialog,
    ItserveButton,
  },
  data() {
    return {
      showStepOne: true,
      showStepTwo: false,
      dialogAnswers: {
        neighbors: null,
        automates: null,
      },
      showDialog: false,
      errorMessage: null,
      dialogMessage: "",
      dialogSuccessMessage: false,
    };
  },
  computed: {
    callerfirstname: {
      get() {
        return this.$store.state.RegionalEnergy.shutDownReport.callerfirstname;
      },
      set(callerfirstname) {
        this.$store.commit(
          "RegionalEnergy/SET_CALLER_FIRST_NAME",
          callerfirstname
        );
      },
    },
    callerlastname: {
      get() {
        return this.$store.state.RegionalEnergy.shutDownReport.callerlastname;
      },
      set(callerlastname) {
        this.$store.commit(
          "RegionalEnergy/SET_CALLER_LAST_NAME",
          callerlastname
        );
      },
    },
    callerparentname: {
      get() {
        return this.$store.state.RegionalEnergy.shutDownReport.callerparentname;
      },
      set(callerparentname) {
        this.$store.commit(
          "RegionalEnergy/SET_CALLER_PARENT_NAME",
          callerparentname
        );
      },
    },
    contents: {
      get() {
        return this.$store.state.RegionalEnergy.shutDownReport.contents;
      },
      set(contents) {
        this.$store.commit(
          "RegionalEnergy/SET_CALLER_REPORT_CONTENTS",
          contents
        );
      },
    },
    errorsData() {
      return this.$store.state.Api.errors;
    },
  },
  methods: {
    goBack() {
      this.$router.push({ name: "Shutdowns", params: this.$route.params });
    },
    saveAutomatesChoice(answer) {
      this.dialogAnswers.automates = answer;
      this.showStepOne = false;

      setTimeout(() => {
        this.showStepTwo = true;
      }, 500);
    },
    saveNeighborsChoice(answer) {
      this.dialogAnswers.neighbors = answer;
      this.showStepTwo = false;
    },
    async sendShutDownReport() {
      await this.$store.commit(
        "RegionalEnergy/SET_CALLER_LS",
        this.$route.params.account
      );
      let res = await this.$store.dispatch(
        "RegionalEnergy/sendShutDownReport",
        this.dialogAnswers
      );
      if ("message" in this.errorsData) {
        this.errorMessage = this.errorsData.message;
      }

      if (this.errorMessage !== "The given data was invalid.") {
        if (res === false) {
          this.dialogMessage = this.$t(
            "regionalEnergy.reportShutdown.alreadySent"
          );
          this.dialogSuccessMessage = false;
        } else {
          this.dialogMessage = this.$t(
            "regionalEnergy.reportShutdown.reportSuccessfullySent"
          );
          this.dialogSuccessMessage = true;
        }
        this.showDialog = true;
      }
    },
  },
};
</script>
<style>
.shutdown-page .info-content {
  text-align: center !important;
}
</style>
<style scoped>
.dialog-content {
  margin: 15px;
}
.report-shutdown-input {
  margin-left: 10px;
  margin-right: 10px;
}
</style>

<template>
  <van-row class="page-wrapper send-indications-photo" justify="center">
    <van-col span="24" class="round-shadow-btn flex-fix">
      <info-message
        class="itserve-center apply-loader place-content-center mt-25"
      >
        {{ $t("sendPhotoPage.sendPhotoPageDesc") }}
      </info-message>
    </van-col>
    <van-col span="24" class="flex-display-fix mt-25">
      <div class="place-content-center flex-column flex-display-fix">
        <itserve-route
          route-name="SendIndications"
          :params="$route.params"
          :buttonName="$t('buttons.sendIndicationsPhotoTryAgain')"
          type="wide"
          class="nav-btn mt-20 try-again-btn"
          :icon="true"
          icon-color="#3C2C93"
        >
          <send-indications-icon />
        </itserve-route>
      </div>
    </van-col>
    <send-counter-photo />
  </van-row>
</template>
<script>
import ItserveRoute from "@/components/content/ItserveRoute";
import InfoMessage from "@/components/content/InfoMessage";
import SendIndicationsIcon from "@/components/icons/send-indications-icon2";
import SendCounterPhoto from "@/components/indications/SendCounterPhoto";

export default {
  name: "SendIndicationsPhoto",
  components: {
    SendCounterPhoto,
    ItserveRoute,
    SendIndicationsIcon,
    InfoMessage,
  },
  computed: {
    appTheme() {
      return this.$store.state.Auth.appTheme;
    },
  },
};
</script>

<template>
  <div class="itserve-input" :class="'size-' + variant">
    <div class="input-wrapper">
      <label
        class="itserve-label textarea-label"
        :for="'_itserve-textarea-' + name"
        :class="
          formErrors !== null &&
          Object.keys(formErrors.errors).indexOf(name) >= 0
            ? `error-label label-show-up show-label`
            : `standard-label ` +
              (content.length ? 'label-show-up show-label' : '')
        "
        >{{
          formErrors !== null &&
          Object.keys(formErrors.errors).indexOf(name) >= 0
            ? formErrors.errors[name][0]
            : placeholder
        }}</label
      >
      <textarea
        :type="type"
        v-model="content"
        @input="changeModelValue(name)"
        :disabled="disabled"
        :name="name"
        :placeholder="placeholder"
        class="itserve-textarea-field"
        :class="
          formErrors !== null &&
          Object.keys(formErrors.errors).indexOf(name) >= 0
            ? 'error-field'
            : ''
        "
      ></textarea>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: [Number, String],
    variant: {
      type: String,
      default: "default",
    },
    name: String,
    type: String,
    placeholder: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formErrors() {
      return this.$store.state.Api.errors;
    },
  },
  data() {
    return {
      content: this.value ? this.value : "",
    };
  },
  methods: {
    changeModelValue(name) {
      this.$emit("input", this.content);
      this.deleteFieldError(name);
    },
    deleteFieldError(name) {
      if (
        this.formErrors !== null &&
        Object.keys(this.formErrors.errors).indexOf(name) >= 0
      ) {
        this.$store.commit("Api/deleteErrorByFieldName", name);
      }
    },
  },
};
</script>
<style scoped>
@import "../../../node_modules/vant/lib/cell-group/index.css";
@import "../../../node_modules/vant/lib/cell/index.css";
@import "../../../node_modules/vant/lib/field/index.css";

.itserve-input {
  margin: 10px;
}
.input-wrapper {
  text-align: center;
  position: relative;
  padding-top: 30px;
}
.itserve-textarea-field:focus {
  border-color: #2e73b4 !important;
}
.itserve-textarea-field {
  width: 100%;
  outline: 0;
  border-width: 0 0 1px;
  border-color: #777777;
  outline: none;
  font-size: 16px;
  max-width: 317px;
  padding: 10px;
  height: 20vh;
  resize: none;
}

.itserve-textarea-field::placeholder {
  font-size: 18px !important;
}
.textarea-label {
  margin-bottom: 5px;
}
</style>

<template>
  <svg
    width="100%"
    height="100%"
    style="margin-top: 2px"
    viewBox="-22 -15 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M44.5971 0H10.6841C4.79183 0 0 4.66677 0 10.4052V43.4331C0 48.6162 3.9215 52.9225 9.02345 53.7019V54.2475C9.02345 59.6255 13.5152 64 19.0373 64H36.3139C41.836 64 46.3277 59.6255 46.3277 54.2475V53.6922C51.3997 52.8835 55.2811 48.5967 55.2811 43.4331V10.4052C55.2811 4.66677 50.4893 0 44.5971 0ZM44.8271 53.8481V54.2573C44.8271 58.8266 41.0057 62.5483 36.3139 62.5483H19.0373C14.3455 62.5483 10.524 58.8266 10.524 54.2573V53.8481H10.6841H18.0269V60.1029H23.1689V53.8481H25.1796V60.1029H30.3216V53.8481H32.4624V60.1029H37.6044V53.8481H44.6171H44.8271ZM19.5275 53.8481H21.6683V58.6415H19.5275V53.8481ZM26.6702 53.8481H28.811V58.6415H26.6702V53.8481ZM33.953 53.8481H36.0938V58.6415H33.953V53.8481ZM53.7806 43.4331C53.7806 48.3629 49.659 52.3769 44.5971 52.3769H37.5844H32.4424H30.3116H25.1696H23.1589H18.0169H10.6741C5.61215 52.3769 1.49057 48.3629 1.49057 43.4331V10.4052C1.49057 5.47542 5.61215 1.46141 10.6741 1.46141H44.587C49.649 1.46141 53.7706 5.47542 53.7706 10.4052V43.4331H53.7806Z"
      fill="white"
    />
    <path
      d="M40.455 4.03345H14.8252C8.93295 4.03345 4.14111 8.70022 4.14111 14.4387V39.3996C4.14111 45.138 8.93295 49.8048 14.8252 49.8048H40.455C46.3473 49.8048 51.1391 45.138 51.1391 39.3996V14.4387C51.1391 8.70022 46.3473 4.03345 40.455 4.03345ZM49.6385 39.3996C49.6385 44.3294 45.5169 48.3434 40.455 48.3434H14.8252C9.76326 48.3434 5.64169 44.3294 5.64169 39.3996V14.4387C5.64169 9.50886 9.76326 5.49486 14.8252 5.49486H40.455C45.5169 5.49486 49.6385 9.50886 49.6385 14.4387V39.3996Z"
      fill="white"
    />
    <path
      d="M38.2039 17.1473H34.0723H32.5717H28.6402H26.9395H23.008H21.5075H17.3759H15.8753H10.2432V28.3028H15.8753H17.3759H21.5075H23.008H26.9395H28.6402H32.5717H34.0723H38.2039H39.7044H45.3366V17.1473H39.7044H38.2039ZM15.8553 26.8511H11.7237V18.6087H15.8553V26.8511V26.8511ZM21.4875 26.8511H17.3559V18.6087H21.4875V26.8511ZM22.988 26.8511V18.6087H26.9195V26.8414H22.988V26.8511ZM32.5617 26.8511H28.6302V18.6087H32.5617V26.8511ZM38.2039 26.8511H34.0723V18.6087H38.2039V26.8511ZM43.836 18.6087V26.8414H39.7044V18.6087H43.836Z"
      fill="white"
    />
    <path
      d="M16.3162 30.3878C14.0853 30.3878 12.2646 32.161 12.2646 34.3336C12.2646 36.5063 14.0853 38.2794 16.3162 38.2794C18.5471 38.2794 20.3677 36.5063 20.3677 34.3336C20.3677 32.1513 18.5471 30.3878 16.3162 30.3878ZM16.3162 36.818C14.9057 36.818 13.7652 35.7074 13.7652 34.3336C13.7652 32.9599 14.9057 31.8492 16.3162 31.8492C17.7267 31.8492 18.8672 32.9599 18.8672 34.3336C18.8672 35.7074 17.7267 36.818 16.3162 36.818Z"
      fill="white"
    />
    <path
      d="M27.7796 30.3878C25.5487 30.3878 23.728 32.161 23.728 34.3336C23.728 36.5063 25.5487 38.2794 27.7796 38.2794C30.0104 38.2794 31.8311 36.5063 31.8311 34.3336C31.8311 32.1513 30.0104 30.3878 27.7796 30.3878ZM27.7796 36.818C26.369 36.818 25.2286 35.7074 25.2286 34.3336C25.2286 32.9599 26.369 31.8492 27.7796 31.8492C29.1901 31.8492 30.3306 32.9599 30.3306 34.3336C30.3306 35.7074 29.1901 36.818 27.7796 36.818Z"
      fill="white"
    />
    <path
      d="M39.2444 30.3878C37.0136 30.3878 35.1929 32.161 35.1929 34.3336C35.1929 36.5063 37.0136 38.2794 39.2444 38.2794C41.4753 38.2794 43.296 36.5063 43.296 34.3336C43.296 32.1513 41.4753 30.3878 39.2444 30.3878ZM39.2444 36.818C37.8339 36.818 36.6934 35.7074 36.6934 34.3336C36.6934 32.9599 37.8339 31.8492 39.2444 31.8492C40.655 31.8492 41.7954 32.9599 41.7954 34.3336C41.7954 35.7074 40.645 36.818 39.2444 36.818Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "send-indications-icon-box",
};
</script>

<style scoped></style>

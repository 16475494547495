<template>
  <div style="background-color: #ffffff">
    <template v-if="agreement === true">
      <arrow-left
          class="itserve-menu-icon"
          :fill-color="'#000000'"
          @click="goBack"
      />
      <span>
      {{ $t("oferta.goBack") }}
    </span>
    </template>
    <div class="iframe-container">
      <iframe
        :src="ofertaUrl"
        style="
          position: fixed;
          width: 100%;
          height: 100%;
          border: none;
          margin: 0;
          padding: 0;
          background-color: #ffffff;
        "
      ></iframe>
    </div>
  </div>
</template>
<script>
import ArrowLeft from "vue-material-design-icons/ArrowLeft";
export default {
  components: {
    ArrowLeft,
  },
  name: "OfertaPage",
  computed: {
    ofertaUrl() {
      return "https://zhitomir.billing.city/docs/oferta";
    },
    agreement() {
      return this.$store.state.Settings.agreement;
    },
  },
  methods: {
    goBack() {
      this.$store.state.Auth.nativeData.agreement = false;
      this.$store.commit("Auth/SET_HIDDEN", false);
      this.$router.push({ name: "Home" });
    },
  },
};
</script>

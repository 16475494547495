<template>
  <div class="layout-page-header">
    <div class="place-content-center">
      <h5 class="apply-loader">
        {{ $t("regionalEnergy.sendIndications.countersZtoek") }}
      </h5>
      <template v-if="lastIndicators === 'Немае показань!'">
        <table class="info-table">
          <tr>
            <th class="apply-loader">
              {{ $t("regionalEnergy.sendIndications.accountNumber") }}
            </th>
            <td class="apply-loader">{{ $route.params.account }}</td>
          </tr>
          <tr>
            <th class="apply-loader">
              {{ $t("regionalEnergy.sendIndications.lastIndicationsDate") }}
            </th>
            <td class="apply-loader">{{ lastIndicators }}</td>
          </tr>
        </table>
      </template>
      <template v-else-if="lastIndicators.value_date.length > 0">
        <table class="info-table">
          <tr>
            <th class="apply-loader">
              {{ $t("regionalEnergy.sendIndications.accountNumber") }}
            </th>
            <td class="apply-loader">{{ $route.params.account }}</td>
          </tr>
          <tr>
            <th class="apply-loader">
              {{ $t("regionalEnergy.sendIndications.lastIndicationsDate") }}
            </th>
            <td class="apply-loader">{{ lastIndicators.value_date.substr(0, 10) }}</td>
          </tr>
        </table>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "SendIndicationsInfoPart",
  computed: {
    lastIndicators() {
      return this.$store.state.RegionalEnergy.lastIndicators;
    },
    error() {
      return this.$store.state.RegionalEnergy.error;
    },
  },
};
</script>
<style scoped>
h5 {
  color: #0b0b0b;
  padding: 0;
  text-align: center;
  font-size: 18px;
  margin: 0;
}
table {
  width: 100%;
}
.info-table th {
  text-align: left;
  padding-left: 24px;
  padding-top: 12px;
  font-size: 16px;
  color: #0b0b0b;
  font-weight: normal;
}
.info-table td {
  font-size: 16px;
  padding-right: 24px;
  text-align: right;
  color: #918dab;
}
</style>

<template>
  <van-col span="24">
    <table class="send-indications-table">
      <tr>
        <th class="apply-loader">
          {{ $t("regionalEnergy.sendIndications.previousIndications") }}
        </th>
        <th class="apply-loader">
          {{ $t("regionalEnergy.sendIndications.newIndications") }}
        </th>
      </tr>
      <template v-if="errors !== null">
        <template
          v-if="Object.keys(errors.errors).indexOf('imageToDetect') >= 0"
        >
          <tr>
            <td colspan="2" class="detection-error">
              {{ errors.errors.imageToDetect[0] }}
            </td>
          </tr>
        </template>
      </template>
      <tr>
        <td class="apply-loader">
          <div class="prev-ind-wrapper">
            {{ lastIndicators.value1 }}
          </div>
        </td>
        <td class="apply-loader">
          <template v-if="photoMode">
            <uploader :after-read="photoSvalueTaken">
              <camera
                :fill-color="appTheme === 'dark' ? '#ffffff' : '#1d1d1d'"
              />
            </uploader>
          </template>
          <template v-else>
            <itserve-input
              v-model.number="indications.value1"
              type="numberFi"
              variant="small"
              name="value1"
              class="apply-loader"
              :placeholder="$t('regionalEnergy.sendIndications.putIndications')"
            />
          </template>
        </td>
      </tr>
    </table>
    <itserve-button
      type="primary"
      :value="$t('buttons.sendIndicationsRegionalEnergy')"
      class="itserve-button-wide mt-15 apply-loader"
      @click="sendIndications"
    ></itserve-button>
    <simple-dialog
      v-model="showDialog"
      :title="dialogMessage"
      :success="dialogSuccessMessage"
      @change="goToHomePage"
    />
    <itserve-button
        type="primary"
        :value="$t('buttons.sendIndicationsRegionalEnergyWithWebSite')"
        class="itserve-button-wide mt-15 apply-loader"
        @click="sendIndicationsWithWebSite"
    ></itserve-button>
  </van-col>
</template>
<script>
import indications from "@/mixins/regional-energy/indications";
import SimpleDialog from "@/components/content/SimpleDialog";
import Camera from "vue-material-design-icons/Camera";
import Uploader from "vant/lib/uploader/index";
export default {
  components: { SimpleDialog, Uploader, Camera },
  mixins: [indications],
  computed: {
    appTheme() {
      return this.$store.state.Auth.appTheme;
    },
  },
  name: "SingleZoneStandart",
};
</script>

<style scoped>
@import "../../../node_modules/vant/lib/uploader/index.css";
.prev-ind-wrapper {
  margin-top: 18px;
}
.send-indications-table {
  margin-top: 10px;
}
table {
  width: 100%;
}
.info-table th {
  text-align: left;
  padding-left: 24px;
  padding-top: 12px;
  font-size: 14px;
  color: #0b0b0b;
  font-weight: normal;
}
.info-table td {
  font-size: 16px;
  padding-right: 24px;
  text-align: right;
  color: #918dab;
}
.send-indications-table th {
  font-size: 16px;
  color: #2e73b4;
  padding-top: 3px;
  text-align: center;
}
.send-indications-table td {
  color: #0b0b0b;
  font-size: 16px;
  padding-top: 7px;
  width: 50%;
}
.indications-block tr th {
  padding-top: 10px;
}
</style>

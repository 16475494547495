<template>
  <van-col span="24">
    <table class="send-indications-table">
      <tr>
        <th class="apply-loader">
          {{ $t("regionalEnergy.sendIndications.previousIndications") }}
        </th>
      </tr>
      <tr>
        <td class="apply-loader">{{ lastIndicators.value1 }}</td>
      </tr>
      <tr>
        <th class="apply-loader">
          {{ $t("regionalEnergy.sendIndications.newIndications") }}
        </th>
      </tr>
      <tr>
        <td class="apply-loader">
          <itserve-input
            v-model.number="indications.value1"
            type="number"
            inputmode="numeric"
            name="value1"
            variant="small"
            :placeholder="$t('regionalEnergy.sendIndications.putIndications')"
          />
        </td>
      </tr>
    </table>
    <itserve-button
      type="primary"
      :value="$t('buttons.sendIndicationsRegionalEnergy')"
      class="itserve-button-wide mt-15 apply-loader"
      @click="sendIndications"
    ></itserve-button>
    <simple-dialog
        v-model="showDialog"
        :title="dialogMessage"
        :success="dialogSuccessMessage"
        @change="goToHomePage"
    />
  </van-col>
</template>

<script>
import indications from "@/mixins/regional-energy/indications";
import SimpleDialog from "@/components/content/SimpleDialog";
export default {
  components: { SimpleDialog },
  mixins: [indications],
  name: "SingleZoneSmall",
};
</script>

<style scoped>
.send-indications-table {
  margin-top: 10px;
}
table {
  width: 100%;
}
.info-table th {
  text-align: left;
  padding-left: 24px;
  padding-top: 12px;
  font-size: 14px;
  color: #0b0b0b;
  font-weight: normal;
}
.info-table td {
  font-size: 16px;
  padding-right: 24px;
  text-align: right;
  color: #918dab;
}
.send-indications-table th {
  font-size: 16px;
  color: #2e73b4;
  padding-top: 3px;
  text-align: center;
}
.send-indications-table td {
  color: #0b0b0b;
  font-size: 16px;
  padding-top: 7px;
  width: 50%;
}
.indications-block tr th {
  padding-top: 10px;
}
</style>

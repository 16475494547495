<template>
  <div>
    <!-- <template v-if="!Object.keys(accounts).length">
      <info-message class="apply-loader">
        {{ $t("components.accounts.howToUseMessage") }}
      </info-message>
      <info-message class="apply-loader">
        {{ $t("components.accounts.howToAddMessage") }}
      </info-message>
    </template> -->
    <template v-if="Object.keys(accounts).length">
      <template v-for="account in accounts">
        <template v-if="link">
          <router-link
            v-bind:key="account.id"
            :to="{
              name: 'Account',
              params: { account: account.accountNumber },
            }"
          >
            <button class="pushable" @click="sendClickEvt">
              <span class="shadow"></span>
              <span class="edge"></span>
              <span class="front">
                <account :account="account" />
              </span>
            </button>
          </router-link>
        </template>
        <template v-else>
          <account v-bind:key="account.id" :account="account" />
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import Account from "@/components/helpers/Account";
import { Dialog } from "vant";

export default {
  name: "Accounts",
  props: {
    link: {
      type: Boolean,
      default: true,
    },
  },
  components: { Account },
  computed: {
    accounts() {
      return this.$store.state.Auth.accounts;
    },
  },
  methods: {
    sendClickEvt() {
      this.nativeSendEvent("bottomBar", {});
    },
    deleteAccount(accountNumber) {
      Dialog.confirm({
        title: this.$t("accountList.dialogDeleteMessage") + accountNumber,
        message: this.$t("accountList.dialogQuestion"),
        cancelButtonText: this.$t("accountList.dialogCancel"),
        confirmButtonText: this.$t("accountList.dialogConfirm"),
      })
        .then(async () => {
          await this.$store.dispatch("Auth/deleteUserAccount", accountNumber);
          await this.$store.dispatch("Auth/getUserAccounts");
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped>
.pushable {
  position: relative;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
  transition: filter 250ms;
}
.shadow {
  position: absolute;
  top: 0;
  left: 4px;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: hsl(0deg 0% 0% / 0.25);
  will-change: transform;
  transform: translateY(2px);
  transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
}
.edge {
  position: absolute;
  top: 0;
  left: 4px;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: linear-gradient(
    to left,
    hsl(0deg 1% 61%) 0%,
    hsl(0deg 1% 61%) 8%,
    hsl(0deg 1% 61%) 92%,
    hsl(0deg 1% 61%) 100%
  );
}

.front {
  display: block;
  position: relative;
  border-radius: 12px;
  font-size: 1.25rem;
  color: white;
  will-change: transform;
  transform: translateY(-4px);
  transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
}
.pushable:hover {
  filter: brightness(110%);
}
.pushable:hover .front {
  transform: translateY(-6px);
  transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
}
.pushable:active .front {
  transform: translateY(-2px);
  transition: transform 34ms;
}
.pushable:hover .shadow {
  transform: translateY(4px);
  transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
}
.pushable:active .shadow {
  transform: translateY(1px);
  transition: transform 34ms;
}
.pushable:focus:not(:focus-visible) {
  outline: none;
}
</style>

<template>
  <van-row class="add-card-page-wraper">
    <template v-if="addCardUrl">
      <div class="iframe-container">
        <iframe
          :src="addCardUrl"
          allowpaymentrequest
          ref="renderAddCard"
          class="add-card-page"
        ></iframe>
      </div>
    </template>
  </van-row>
</template>
<script>
export default {
  name: "AddCardPage",
  computed: {
    addCardUrl() {
      return this.$store.state.Payment.addCardUrl;
    },
  },
  data() {
    return {
      watchInterval: null,
    };
  },
  created() {
    this.getLinkToAddCard();
    this.runCheck();
  },
  destroyed() {
    clearInterval(this.watchInterval);
  },
  methods: {
    runCheck() {
      this.watchInterval = setInterval(() => {
        try {
          let url = this.$refs.renderAddCard.contentWindow.location.href;
          if (url.match(process.env.VUE_APP_PRODUCTION_URL) !== null) {
            clearInterval(this.watchInterval);
            this.$router.push(
              url.replace(process.env.VUE_APP_PRODUCTION_URL, "")
            );
          }
        } catch (e) {
          console.log("Easy-pay handling user");
        }
      }, 200);
    },
    getLinkToAddCard() {
      this.$store.dispatch("Payment/getSaveCardPage");
    },
  },
};
</script>

<style scoped>
.iframe-container {
  overflow: auto;
  -webkit-overflow-scrolling: touch !important;
  height: calc(100vh - 120px);
}
.iframe-container iframe {
  height: calc(100vh - 120px);
  width: 1px;
  min-width: 100%;
  *width: 100%;
}
.add-card-page {
  border: none !important;
}
.add-card-page-wraper {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
}
</style>

<template>
  <van-col span="24">
    <table class="send-indications-table">
      <template v-for="index in meterageZone">
        <tbody :key="index" class="indications-block">
          <tr class="standart-size">
            <th class="apply-loader">
              {{ $t("regionalEnergy.sendIndications.previousIndications") }}
            </th>
            <th class="apply-loader">
              {{
                $t(
                  "regionalEnergy.sendIndications.newIndications" +
                    (meterageZone === 2 ? "" : "_") +
                    (index + (meterageZone === 2 ? 1 : 0))
                )
              }}
            </th>
          </tr>
          <tr class="standart-size">
            <td class="apply-loader">
              <div class="prev-ind-wrapper">
                {{
                  lastIndicators[
                    `value${index + (meterageZone === 4 ? 2 : 0)}`
                  ]
                }}
              </div>
            </td>
            <td class="apply-loader">
              <itserve-input
                type="number"
                inputmode="numeric"
                v-model.number="
                  indications[
                    `value${index + (meterageZone === 4 ? 2 : 0)}`
                  ]
                "
                variant="small"
                :name="`value${index + (meterageZone === 4 ? 2 : 0)}`"
                :placeholder="
                  $t('regionalEnergy.sendIndications.putIndications')
                "
              />
            </td>
          </tr>
        </tbody>
      </template>
    </table>
    <itserve-button
      type="primary"
      :value="$t('buttons.sendIndicationsRegionalEnergy')"
      class="itserve-button-wide mt-15 apply-loader"
      @click="sendIndications"
    ></itserve-button>
    <simple-dialog
      v-model="showDialog"
      :title="dialogMessage"
      :success="dialogSuccessMessage"
      @change="goToHomePage"
    />
  </van-col>
</template>

<script>
import indications from "@/mixins/regional-energy/indications";
import SimpleDialog from "@/components/content/SimpleDialog";
export default {
  components: { SimpleDialog },
  mixins: [indications],
  name: "MultiZoneStandart",
};
</script>

<style scoped>
.prev-ind-wrapper {
  margin-top: 17px;
}
.send-indications-table {
  margin-top: 10px;
}
table {
  width: 100%;
}
.info-table th {
  text-align: left;
  padding-left: 24px;
  padding-top: 12px;
  font-size: 16px;
  color: #0b0b0b;
  font-weight: normal;
}
.info-table td {
  font-size: 16px;
  padding-right: 24px;
  text-align: right;
  color: #918dab;
}
.send-indications-table th {
  font-size: 16px;
  color: #2e73b4;
  padding-top: 3px;
  text-align: center;
}
.send-indications-table td {
  color: #0b0b0b;
  font-size: 16px;
  padding-top: 7px;
  width: 50%;
}
.indications-block tr th {
  padding-top: 10px;
}
</style>

<template>
  <van-row justify="center" class="page-wrapper shutdown-main-page">
    <template v-if="shutdownInfo !== null">
      <van-col
        span="24"
        class="itserve-center round-shadow-btn container_width mt-15"
      >
        <div class="place-content-center">
          <template v-if="!shutdownInfo.disconnects.length">
            <info-message class="apply-loader">
              {{
                $t("regionalEnergy.shutdowns.noEmergencyShutdowns")
              }}</info-message
            >
          </template>
          <template v-else>
            <template v-for="disconnect in shutdownInfo.disconnects">
              <info-message :key="disconnect.discon_date">
                <h3 class="shutdowns-title itserve-center apply-loader">
                  <template
                    v-if="disconnect.discon_type.toUpperCase() === 'ПВ'"
                  >
                    {{ $t("regionalEnergy.shutdowns.plannedShutdown") }}
                  </template>
                  <template v-else>
                    {{ $t("regionalEnergy.shutdowns.emergencyShutdown") }}
                  </template>
                </h3>
                <van-cell
                  class="shutdown-row-title apply-loader"
                  :title="$t('regionalEnergy.shutdowns.timeOfShutdown')"
                  :value="disconnect.discon_date"
                />
                <van-cell
                  class="shutdown-row-title apply-loader"
                  :title="$t('regionalEnergy.shutdowns.timeOfConnection')"
                  :value="disconnect.indicat_inclus_date"
                />
                <p class="itserve-center typeof-shutdown">
                  {{ disconnect.discon_comm }}
                </p>
              </info-message>
            </template>
          </template>
        </div>
      </van-col>
    </template>
    <template v-else>
      <van-col span="24" class="itserve-center round-shadow-btn">
        <div class="place-content-center">
          <info-message class="apply-loader">
            {{
              $t("regionalEnergy.shutdowns.noEmergencyShutdowns")
            }}</info-message
          >
        </div>
      </van-col>
    </template>
    <van-col span="24" class="bottom-bar itserve-center">
      <div class="place-content-center">
        <info-message class="apply-loader">
          {{ $t("regionalEnergy.shutdowns.noElectricMessage") }}
        </info-message>
        <itserve-route
          route-name="ReportShutdown"
          :buttonName="$t('buttons.reportShutdown')"
          class="mb-25"
          :params="$route.params"
        ></itserve-route>
      </div>
    </van-col>
  </van-row>
</template>
<script>
import { Cell } from "vant";
import ItserveRoute from "@/components/content/ItserveRoute";
import InfoMessage from "@/components/content/InfoMessage";
import "vue-awesome/icons/exclamation";
export default {
  name: "Shutdowns",
  components: { InfoMessage, ItserveRoute, VanCell: Cell },
  computed: {
    shutdownInfo() {
      return this.$store.state.RegionalEnergy.shutdownInfo;
    },
  },
  created() {
    this.$store.dispatch(
      "RegionalEnergy/getShutdownInfo",
      this.$route.params.account
    );
  },
};
</script>
<style>
.shutdown-main-page .info-content {
  text-align: center !important;
}
</style>
<style scoped>
@import "../../../../node_modules/vant/lib/cell/index.css";

.shutdowns-title {
  color: #2e73b4;
}
.shutdown-row-title {
  color: #0b0b0b;
}
.typeof-shutdown {
  margin: 10px 0 0 0;
  padding: 0;
}
</style>

<template>
  <van-row class="page-wrapper add-account-page" justify="center">
    <van-col
      span="24"
      class="itserve-center round-shadow-btn flex-display-fix mb-25"
    >
      <div class="place-content-center">
        <info-message class="apply-loader add-account-page-message">{{
          $t("addAccount.specifyAccountNumber")
        }}</info-message>
      </div>
      <van-form>
        <v-text-field
          v-model="accountNumber"
          :placeholder="$t('addAccount.accountNumber')"
          class="mt-10"
        ></v-text-field>

        <itserve-button
          :value="$t('buttons.addAccount')"
          type="primary"
          @click="addAccountNumber"
          class="mt-15 apply-loader"
        ></itserve-button>
      </van-form>
      <van-col class="itserve-center" span="24">
        <itserve-route
          :buttonName="$t('buttons.addAccountEic')"
          route-name="AddAccountEic"
          type="wide"
        ></itserve-route>
      </van-col>
      <van-col class="itserve-center" span="24">
        <itserve-route
            :buttonName="$t('buttons.addAccountAddress')"
            route-name="AddCity"
            type="wide"
        ></itserve-route>
      </van-col>
      <div class="place-content-center">
        <info-message class="apply-loader add-account-page-message">{{
          $t("addAccount.info")
        }}</info-message>
      </div>
      <simple-dialog
        v-model="showDialog"
        :title="addAccountResponses[addAccountMode - 1]"
        :success="addAccountMode === 1"
        @change="goToHomePage"
      />
      <simple-dialog
        v-model="showImportDialog"
        :title="responses[mode - 1]"
        :success="mode === 1"
        @change="goToHomePageImport"
      />
    </van-col>

    <!--    <template v-if="!Object.keys(accounts).length">-->
    <!--      <van-col span="24" class="round-shadow pb-25 mb-25 flex-display-fix">-->
    <!--        <info-message class="place-content-center import-message">{{-->
    <!--          $t("addAccount.importText")-->
    <!--        }}</info-message>-->
    <!--        <div class="itserve-center">-->
    <!--          <itserve-button-->
    <!--            :value="$t('buttons.importAccounts')"-->
    <!--            type="primary"-->
    <!--            @click="importAccounts"-->
    <!--            class="mt-15 import-btn"-->
    <!--          ></itserve-button>-->
    <!--        </div>-->
    <!--      </van-col>-->
    <!--    </template>-->
  </van-row>
</template>
<script>
import Form from "vant/lib/form/index";
import ItserveButton from "@/components/forms/ItserveButton";
import ItserveRoute from "@/components/content/ItserveRoute";
import InfoMessage from "@/components/content/InfoMessage";
import "vue-awesome/icons/plus";
import "vue-awesome/icons/phone";
import SimpleDialog from "@/components/content/SimpleDialog";
export default {
  name: "Index",
  computed: {
    accounts() {
      return this.$store.state.Auth.accounts;
    },
    accountNumber: {
      get() {
        return this.$store.state.Auth.accountNumber;
      },
      set(accountNumber) {
        this.$store.commit("Auth/SET_ACCOUNT_NUMBER", accountNumber);
      },
    },
  },
  created() {
    this.$store.commit("Auth/SET_ACCOUNT_NUMBER", null);
    this.$store.dispatch("Auth/getUserAccounts");
  },
  data() {
    return {
      showDialog: false,
      showImportDialog: false,
      mode: 0,
      addAccountMode: 0,
      addAccountResponses: [
        this.$t("addAccount.successfullyAdded"),
        this.$t("addAccount.notFound"),
        this.$t("addAccount.alreadyAdded"),
        this.$t("addAccount.emptyField"),
      ],
      responses: [
        this.$t("addAccount.successfullyAdded"),
        this.$t("addAccount.alreadyAdded"),
        this.$t("addAccount.notFound"),
        this.$t("addAccount.oldVersionNotFound"),
      ],
    };
  },
  components: {
    SimpleDialog,
    InfoMessage,
    ItserveButton,
    ItserveRoute,
    VanForm: Form,
  },
  methods: {
    async importAccounts() {
      this.mode = await this.$store.dispatch("Auth/importAccounts");
      this.showImportDialog = true;
    },
    goToHomePageImport() {
      if (this.mode === 1) {
        this.$router.push({ name: "Home" });
      }
    },
    goToHomePage() {
      if (this.addAccountMode === 1) {
        this.$router.push({ name: "Home" });
      }
    },
    addAccountNumber() {
      this.$store.dispatch("Auth/storeNewAcont", (res) => {
        if (Object.keys(res).length >= 0) {
          this.addAccountMode = res.mode;
          this.showDialog = true;
        } else {
          console.error(
            `Unknown response from server, mode doesn't exists ${JSON.stringify(
              res
            )}`
          );
        }
      });
    },
  },
};
</script>
<style>
.add-account-page-message .info-content,
.import-message .info-content {
  text-align: center !important;
}
</style>
<style scoped>
@import "../../../node_modules/vant/lib/button/index.css";
@import "../../../node_modules/vant/lib/dialog/index.css";
@import "../../../node_modules/vant/lib/overlay/index.css";
@import "../../../node_modules/vant/lib/popup/index.css";
</style>
<style>
.v-text-field input {
  text-align: center;
  text-align: -moz-center;
  text-align: -webkit-center;
  width: 100%;
  outline: 0;
  border-width: 0 0 1px;
  border-color: #777777;
  outline: none;
  font-size: 16px;
  padding: 10px 10px 10px 10px;
  max-width: 337px;
  box-sizing: border-box;
  background-color: #fff;
}
</style>

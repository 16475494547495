<template>
  <div style="background-color: #ffffff">
    <div class="iframe-container">
      <iframe
        :src="zhoekWebSite"
        style="
          position: fixed;
          width: 100%;
          height: 100%;
          border: none;
          margin: 0;
          padding: 0;
          background-color: #ffffff;
        "
      ></iframe>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  name: "ZhoekWebPage",
  computed: {
    zhoekWebSite() {
      return "https://www.ztoe.com.ua/my.php";
    },
  },
};
</script>

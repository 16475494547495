<template>
  <van-row justify="center" class="page-wrapper">
    <template v-if="notifications.length">
      <van-col span="24" class="flex-display-fix">
        <template v-for="notification in notifications">
          <template v-if="notification">
            <itserve-notification
              v-bind:key="notification.id"
              :title="notification.title"
              :date="formatDate(notification.updated_at)"
              :status="notification.read_status"
              :id="notification.id"
              >{{ notification.text }}</itserve-notification
            >
          </template>
        </template>
      </van-col>
    </template>
    <template v-else>
      <van-col span="24" class="round-shadow-btn">
        <div class="place-content-center">
          <itserve-notification :title="$t('notifications.notFoundTitle')">{{
            $t("notifications.notFoundText")
          }}</itserve-notification>
        </div>
      </van-col>
    </template>
  </van-row>
</template>
<script>
import ItserveNotification from "@/components/content/ItserveNotification";
export default {
  components: {
    ItserveNotification,
  },
  data() {
    return {
      notifyParams: this.$route.params,
    };
  },
  computed: {
    notifications() {
      return this.$store.state.Notifications.notifications;
    },
  },
  watch: {
    notifications(notificationsList) {
      if (notificationsList.length) {
        let markAsRead = notificationsList
          .filter((notification) => {
            if (notification.read_status === 0) {
              return true;
            }
            return false;
          })
          .map((notification) => {
            return notification.id;
          });
        if (markAsRead.length) {
          this.$store.dispatch("Notifications/markAsRead", markAsRead);
        }
      }
    },
  },
  created() {
    if (this.notifyParams.account) {
      this.$store.dispatch(
        "Notifications/getNotificationsByAccount",
        this.notifyParams.account
      );
    } else {
      this.$store.dispatch("Notifications/getNotifications");
    }
  },
  name: "Index",
  methods: {
    formatDate(date) {
      let dateToFormat = new Date(date);

      let hours = dateToFormat.getHours();
      hours = hours < 10 ? "0" + hours : hours;

      let minutes = dateToFormat.getMinutes();
      minutes = minutes < 10 ? "0" + minutes : minutes;

      let day = dateToFormat.getDate();
      day = day < 10 ? "0" + day : day;

      return `${day}-${
        dateToFormat.getMonth() + 1
      }-${dateToFormat.getFullYear()} ${hours}:${minutes} `;
    },
  },
};
</script>
<style scoped>
.page-wrapper {
  background-color: #ffffff;
}
.flex-display-fix {
  background-color: #ffffff;
}
</style>

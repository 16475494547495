<template>
  <van-row class="page-wrapper" justify="center">
    <van-col class="itserve-center mb-25" span="24">
      <template v-if="account">
        <div class="white-wrapper place-content-center mt-15 top-radius">
          <div class="apply-loader">
            <Image
              col="0"
              src="~/img/logozhoek.png"
              width="300"
              marginTop="20"
              stretch="aspectFill"
              class="company-logo m-t-10"
            />
          </div>
        </div>
        <account
          :account="account"
          :show-balance="true"
          class="home-account-shadow place-content-center"
        />
      </template>
      <div class="main-page-account place-content-center">
        <template v-if="account">
          <itserve-route
            route-name="RegionalEnergy"
            :params="{
              account: $route.params.account,
            }"
            :buttonName="$t('buttons.putIndications')"
            type="wide"
            class="nav-btn mt-15-simple"
            :icon="true"
            icon-color="#3C2C93"
            btn-style="box"
          >
            <send-indications-icon />
          </itserve-route>
        </template>
        <itserve-route
          route-name="Pay"
          :params="$route.params"
          :buttonName="$t('buttons.pay')"
          type="wide"
          class="nav-btn mt-15-simple fr"
          :icon="true"
          icon-color="#1AAD62"
          btn-style="box"
        >
          <pay-icon />
        </itserve-route>
        <itserve-route
          route-name="PaymentHistory"
          :buttonName="$t('buttons.paymentHistory')"
          type="wide"
          class="nav-btn mt-15-simple mt-5"
          :icon="true"
          icon-color="#000000"
          btn-style="box"
        >
          <payment-history-icon />
        </itserve-route>
        <!--        <template v-if="account">-->
        <!--          <itserve-route-->
        <!--            :route-name="-->
        <!--              account.accountVerified === 1 ? 'Shutdowns' : 'Verification'-->
        <!--            "-->
        <!--            :buttonName="$t('buttons.shutdowns')"-->
        <!--            :params="{-->
        <!--              account: $route.params.account,-->
        <!--              requested: account.accountVerified === 1 ? null : 'Shutdowns',-->
        <!--            }"-->
        <!--            type="wide"-->
        <!--            class="nav-btn mt-15-simple mt-5"-->
        <!--            :icon="true"-->
        <!--            icon-color="#FF8573"-->
        <!--          >-->
        <!--            <shutdown-icon />-->
        <!--          </itserve-route>-->
        <!--        </template>-->

        <itserve-route
          route-name="WriteTicket"
          :buttonName="$t('buttons.contactUs')"
          :params="$route.params"
          type="wide"
          class="nav-btn mt-15-simple fr"
          :icon="true"
          icon-color="#FCC204"
          btn-style="box"
        >
          <contactus-icon />
        </itserve-route>
        <div @click="socialShare">
          <itserve-route
            :buttonName="$t('buttons.socialShare')"
            type="wide"
            class="nav-btn mt-15-simple"
            :icon="true"
            icon-color="#0fb1e5"
            btn-style="box"
          >
            <share-icon size="85%" />
          </itserve-route>
        </div>
        <itserve-route
          route-name=""
          :buttonName="$t('buttons.shutdowns')"
          :params="$route.params"
          type="wide"
          class="nav-btn mt-15-simple fr disabled-box"
          :icon="true"
          icon-color="#FF8573"
          btn-style="box"
        >
          <shutdown-icon />
        </itserve-route>
      </div>
    </van-col>
  </van-row>
</template>
<script>
import ItserveRoute from "@/components/content/ItserveRoute";
import Account from "@/components/helpers/Account";
import PayIcon from "@/components/icons/pay-icon-box";
import ShutdownIcon from "@/components/icons/shutdown-icon-box";
import ContactusIcon from "@/components/icons/contactus-icon-box";
import SendIndicationsIcon from "@/components/icons/send-indications-icon-box";
// import ElectricIcon from "@/components/icons/electric-icon-box";
import PaymentHistoryIcon from "@/components/icons/payment-history-icon-box";
// import ItserveButton from "@/components/forms/ItserveButton";
import ShareIcon from "vue-material-design-icons/Share";
export default {
  name: "Index",
  components: {
    // ItserveButton,
    SendIndicationsIcon,
    PaymentHistoryIcon,
    // ElectricIcon,
    ContactusIcon,
    ShutdownIcon,
    PayIcon,
    Account,
    ItserveRoute,
    ShareIcon,
  },
  computed: {
    account() {
      return this.$store.state.Auth.account;
    },
  },
  created() {
    this.getPageData();
  },
  methods: {
    async getPageData() {
      await this.checkForCorrectParameters();
      await this.$store.dispatch("Payment/getCardsList");
    },
    async checkForCorrectParameters() {
      if (Object.keys(this.$route.params).length) {
        if (this.$route.params.account !== undefined) {
          await this.$store.dispatch(
            "Auth/getUserAccount",
            this.$route.params.account
          );
        } else {
          console.error(
            "WARNING: somehow user opened account page without provided account parameter!"
          );
          await this.$router.push({ name: "Home" });
        }
      } else {
        console.error(
          "WARNING: somehow user opened account page any parameters!"
        );
        await this.$router.push({ name: "Home" });
      }
    },
    socialShare() {
      this.nativeSendEvent("socialShare", {
        text:
          this.$t("settings.title") +
          " " +
          "https://zhitomyrenergo.page.link/share",
        subject: this.$t("settings.subject"),
      });
    },
  },
};
</script>
<style scoped>
@import "../../../node_modules/vant/lib/dialog/index.css";
@import "../../../node_modules/vant/lib/overlay/index.css";
@import "../../../node_modules/vant/lib/popup/index.css";
.main-page-account {
  padding-bottom: 45px;
}
.page-type {
  padding: 15px 0 0 0;
  font-size: 18px;
  margin: 0;
  color: #0b0b0b;
  background-color: #fff;
}
.home-account-shadow {
  border-radius: 0 0 15px 15px;
  -webkit-box-shadow: 0 6px 4px 0 rgba(34, 60, 80, 0.17);
  -moz-box-shadow: 0 6px 4px 0 rgba(34, 60, 80, 0.17);
  box-shadow: 0 6px 4px 0 rgba(34, 60, 80, 0.17);
}
.top-radius {
  border-radius: 15px 15px 0 0;
}
</style>

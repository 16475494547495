<template>
  <div class="app-content">
    <div class="screen-holder"></div>
    <router-view />
    <!-- <global-error :show="showError" /> -->
  </div>
</template>

<script>
//import GlobalError from "../content/GlobalError";
export default {
  name: "AppContent",
  //components: { GlobalError },
  computed: {
    showError() {
      return this.$store.state.Api.requestError;
    },
  },
};
</script>

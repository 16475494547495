export const state = {
  datePickerOpened: false,
};

export const mutations = {
  SET_DATEPICKER_OPENED(state) {
    state.datePickerOpened = true;
  },
  SET_DATEPICKER_CLOSED(state) {
    state.datePickerOpened = false;
  },
};

<template>
  <van-row justify="center" class="page-wrapper">
    <van-col span="24" class="flex-display-fix">
      <template v-if="feedback.length">
        <van-list>
          <template v-for="ticket in feedback">
            <van-row
              :key="ticket.id"
              class="ticket place-content-center"
              :class="ticket.status ? 'answered-ticket' : 'opened-ticket'"
            >
              <van-col span="24" class="apply-loader">
                <span class="ticket-title">
                  {{ ticket.title }}
                </span>
                <p class="ticket-desc">
                  {{ ticket.description }}
                </p>
                <template v-if="ticket.answer !== null">
                  <v-divider></v-divider>
                  <h5 class="ticket-desc ticket-answer"> {{ $t("support.answer") }}</h5>
                  <p class="ticket-desc ticket-answer">{{ ticket.answer }}</p>
                </template>
              </van-col>
              <van-col
                span="12"
                class="ticket-status apply-loader"
                :class="ticket.status ? 'handled' : 'not-handled'"
              >
                <template v-if="ticket.status">
                  {{ $t("support.handled") }}
                </template>
                <template v-else>
                  {{ $t("support.process") }}
                </template>
              </van-col>
              <van-col
                span="12"
                class="ticket-date apply-loader"
                :class="ticket.status ? 'handled' : 'not-handled'"
              >
                {{ formatDate(ticket.updated_at) }}
              </van-col>
            </van-row>
          </template>
        </van-list>
      </template>
      <template v-else>
        <div class="place-content-center">
          <info-message class="no-ticket-message">{{
            $t("support.noTickets")
          }}</info-message>
        </div>
      </template>
    </van-col>
  </van-row>
</template>
<script>
import List from "vant/lib/list/index";
import InfoMessage from "@/components/content/InfoMessage";

export default {
  name: "Index",
  components: { InfoMessage, VanList: List },
  computed: {
    feedback() {
      return this.$store.state.FeedBack.feedback;
    },
  },
  created() {
    this.$store.dispatch("FeedBack/getFeedBack");
  },
  methods: {
    formatDate(date) {
      let dateToFormat = new Date(date);

      let hours = dateToFormat.getHours();
      hours = hours < 10 ? "0" + hours : hours;

      let minutes = dateToFormat.getMinutes();
      minutes = minutes < 10 ? "0" + minutes : minutes;

      let day = dateToFormat.getDate();
      day = day < 10 ? "0" + day : day;

      return `${day}-${
        dateToFormat.getMonth() + 1
      }-${dateToFormat.getFullYear()} ${hours}:${minutes} `;
    },
  },
};
</script>
<style>
.no-ticket-message .info-content {
  text-align: center;
}
</style>
<style scoped>
@import "../../../../node_modules/vant/lib/cell/index.css";
@import "../../../../node_modules/vant/lib/list/index.css";

.flex-display-fix {
  background-color: #ffffff;
  flex-grow: 1;
}
.ticket-status {
  text-align: left;
  font-size: 12px;
  padding-top: 3px;
}

.ticket {
  border-bottom: 2px solid #918dab;
  padding: 12px 24px 5px;
}
.ticket-title {
  font-size: 20px;
  color: #0b0b0b;
}
.ticket-desc {
  font-size: 18px;
  margin: 5px 0 0;
  color: #918dab;
}
.ticket-answer {
  padding-left: 20px;
  font-size: 18px;
}
.ticket-date {
  text-align: center;
  color: #cccccc;
  font-size: 14px;
  padding-top: 6px;
  padding-right: 5px;
}
.ticket-status {
  font-size: 14px;
}
</style>

<template>
  <svg
    width="100%"
    style="margin-top: 3px"
    height="100%"
    viewBox="-32 -23 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_410:1433)">
      <path
        d="M47.6758 0H17.5928C17.1727 0 16.8039 0.228323 16.6194 0.585699L4.55958 12.26C4.45712 12.3592 4.37515 12.4784 4.32392 12.6074C4.27269 12.7365 4.24195 12.8754 4.24195 13.0144V53.656L1.31152 56.515C0.461081 57.3488 0 58.4507 0 59.6321C0 60.8035 0.481574 61.9054 1.34226 62.7393C2.1927 63.5632 3.33003 64.0099 4.53909 64.0099H4.55958C5.77889 64.0099 6.91622 63.5433 7.76666 62.7194L9.60074 60.9325H42.1121C45.7803 60.9325 48.7722 58.0437 48.7722 54.4799V1.0622C48.7619 0.476501 48.2803 0 47.6758 0ZM42.1121 58.7982H11.7525L18.7506 51.9584C21.1995 53.2589 23.9352 53.9438 26.6915 53.9438C26.9374 53.9438 27.1833 53.9339 27.4292 53.924C31.8658 53.7354 35.9746 51.879 38.9767 48.6924C41.9891 45.5058 43.5363 41.3861 43.3416 37.0876C43.147 32.7793 41.2309 28.8084 37.9521 25.8899C34.8782 23.1599 30.872 21.651 26.7017 21.651C26.4558 21.651 26.2099 21.651 25.964 21.6709C16.7834 22.058 9.65197 29.6125 10.0516 38.5072C10.1643 41.0684 10.9328 43.58 12.2545 45.8136L6.41415 51.5117V14.0766H17.5723C18.1769 14.0766 18.6687 13.6001 18.6687 13.0144V2.11447H46.5692V54.47C46.5795 56.8624 44.5712 58.7982 42.1121 58.7982ZM16.0354 28.3319C18.6482 25.5722 22.2139 23.9541 26.0767 23.7953C26.2919 23.7853 26.5173 23.7853 26.7325 23.7853C30.3494 23.7853 33.8126 25.0957 36.4869 27.4683C39.3354 29.9997 41.0055 33.4543 41.1694 37.1968C41.3436 40.9393 39.9911 44.523 37.3886 47.2828C34.7758 50.0425 31.2101 51.6606 27.3472 51.8194C27.1321 51.8294 26.9169 51.8294 26.7017 51.8294C18.9351 51.8294 12.5926 45.9426 12.2443 38.4179C12.0803 34.6754 13.4226 31.0917 16.0354 28.3319ZM13.5046 47.6302C14.4677 48.8413 15.5948 49.9035 16.8653 50.8069L6.19898 61.2204C5.75839 61.6473 5.17436 61.8855 4.52884 61.8855C3.90382 61.8855 3.30954 61.6572 2.8792 61.2303C1.95703 60.3468 1.95703 58.8975 2.86895 58.014L13.5046 47.6302ZM7.97158 11.9522L16.4862 3.70281V11.9522H7.97158Z"
        fill="white"
      />
      <path
        d="M19.9297 45.4959H19.9809C20.5854 45.4661 21.0465 44.9697 21.026 44.3939L20.5342 33.6528C20.5137 33.087 20.0321 32.6403 19.3968 32.6403C18.7923 32.67 18.3312 33.1664 18.3517 33.7422L18.8436 44.4833C18.864 45.0491 19.3456 45.4959 19.9297 45.4959Z"
        fill="white"
      />
      <path
        d="M30.9331 45.0294H30.9844C31.5889 44.9996 32.05 44.5032 32.0295 43.9274L31.5377 33.1863C31.5069 32.6205 31.0356 32.1738 30.4003 32.1738C30.1134 32.1837 29.8368 32.3127 29.6421 32.5212C29.4474 32.7297 29.3449 32.9977 29.3552 33.2856L29.847 44.0267C29.8675 44.5826 30.3491 45.0294 30.9331 45.0294Z"
        fill="white"
      />
      <path
        d="M35.4415 44.8308H35.4928C36.0973 44.8011 36.5584 44.3047 36.5379 43.7289L36.21 36.4722C36.1793 35.9064 35.7079 35.4597 35.0727 35.4597C34.7858 35.4696 34.5091 35.5986 34.3145 35.8071C34.1198 36.0156 34.0173 36.2836 34.0276 36.5715L34.3554 43.8282C34.3759 44.3841 34.8575 44.8308 35.4415 44.8308Z"
        fill="white"
      />
      <path
        d="M25.431 45.2577H25.4822C26.0867 45.2279 26.5478 44.7315 26.5273 44.1558L25.8408 28.9772C25.8203 28.4114 25.3285 27.9647 24.7035 27.9647C24.099 27.9944 23.6379 28.4908 23.6584 29.0666L24.3449 44.2451C24.3654 44.8209 24.8469 45.2577 25.431 45.2577Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_410:1433">
        <rect width="48.7619" height="64" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "indications-history-icon",
};
</script>

<style scoped></style>

<template>
  <div class="itserve-input" :class="'size-' + variant">
    <div class="input-wrapper">
      <label
        class="itserve-label"
        :for="'_itserve-input-' + name"
        :class="
          formErrors !== null &&
          Object.keys(formErrors.errors).indexOf(name) >= 0
            ? `error-label label-show-up show-label`
            : `standard-label ` +
              (content.length ? 'label-show-up show-label' : '')
        "
        >{{
          formErrors !== null &&
          Object.keys(formErrors.errors).indexOf(name) >= 0
            ? formErrors.errors[name][0]
            : placeholder
        }}</label
      >
      <input
        :id="'_itserve-input-' + name"
        :type="type"
        :inputmode="inputmode"
        v-model="content"
        @input="changeModelValue(name)"
        :disabled="disabled"
        :pattern="type === 'number' ? '\d*' : ''"
        :name="name"
        :placeholder="placeholder"
        class="itserve-input-field"
        :class="
          formErrors !== null &&
          Object.keys(formErrors.errors).indexOf(name) >= 0
            ? 'error-field'
            : ''
        "
      />
    </div>
  </div>
</template>
<script>
import appBridge from "@/mixins/app-bridge";
export default {
  mixins: [appBridge],
  props: {
    value: [Number, String],
    variant: {
      type: String,
      default: "default",
    },
    name: String,
    type: String,
    inputmode: String,
    placeholder: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formErrors() {
      return this.$store.state.Api.errors;
    },
  },
  data() {
    return {
      content: this.value ? this.value : "",
    };
  },
  methods: {
    changeModelValue(name) {
      this.$emit("input", this.content);
      this.deleteFieldError(name);
    },
    deleteFieldError(name) {
      if (
        this.formErrors !== null &&
        Object.keys(this.formErrors.errors).indexOf(name) >= 0
      ) {
        this.$store.commit("Api/deleteErrorByFieldName", name);
      }
    },
  },
};
</script>
<style scoped>
@import "../../../node_modules/vant/lib/cell-group/index.css";
@import "../../../node_modules/vant/lib/cell/index.css";
@import "../../../node_modules/vant/lib/field/index.css";
.itserve-label {
  text-align: left;
}
.itserve-input {
  margin: 10px;
}
.itserve-input:focus::placeholder {
  color: transparent;
}
.input-wrapper {
  text-align: center;
  position: relative;
  padding-top: 10px;
}
.itserve-input-field:focus {
  border-color: #2e73b4 !important;
  background-color: #ffffff;
}

.itserve-input-field::placeholder {
  font-size: 18px;
}
.itserve-input-field {
  width: 100%;
  outline: 0;
  border-width: 0 0 1px;
  border-color: #777777;
  outline: none;
  font-size: 16px;
  padding: 10px 10px 10px 10px;
  max-width: 337px;
  box-sizing: border-box;
  background-color: #fff;
  text-align: center;
}
</style>
<style>
.size-small {
  margin-top: 0 !important;
}
.size-small label {
  margin-left: 0;
}
.size-small input {
  width: 100% !important;
  padding: 2px 2px 2px 0 !important;
}
.size-small input::placeholder {
  text-align: center;
  font-size: 7px !important;
}
</style>

<template>
  <van-row class="page-wrapper have-bottom-menu" justify="center">
    <van-col class="itserve-center round-shadow-btn" span="24">
      <div class="new-ticket-page">
        <itserve-input
          v-model="title"
          :placeholder="$t('appSupport.problemTitle')"
          name="title"
          class="apply-loader"
          type="text"
        />
        <itserve-textarea
          v-model="description"
          :placeholder="$t('appSupport.problemDescription')"
          name="description"
          class="apply-loader"
          type="text"
        />
      </div>
      <itserve-button
        :value="$t('buttons.sendTicket')"
        class="itserve-button-wide mt-15 apply-loader"
        type="primary"
        @click="sendTicket"
      ></itserve-button>
      <simple-dialog
        v-model="showDialog"
        :title="dialogMessage"
        :success="true"
        @change="goToTickets"
      />
    </van-col>
  </van-row>
</template>
<script>
import ItserveInput from "@/components/forms/ItserveInput";
import ItserveButton from "@/components/forms/ItserveButton";
import "vue-awesome/icons/paper-plane";
import SimpleDialog from "@/components/content/SimpleDialog";
import ItserveTextarea from "@/components/forms/ItserveTextarea";
export default {
  name: "AppSupport",
  components: { ItserveTextarea, SimpleDialog, ItserveButton, ItserveInput },
  computed: {
    title: {
      get() {
        return this.$store.state.FeedBack.ticket.title;
      },
      set(title) {
        this.$store.commit("FeedBack/UPDATE_TITLE", title);
      },
    },
    description: {
      get() {
        return this.$store.state.FeedBack.ticket.description;
      },
      set(description) {
        this.$store.commit("FeedBack/UPDATE_DESCRIPTION", description);
      },
    },
  },
  data() {
    return {
      showDialog: false,
      dialogMessage: "",
      account: this.$route.params.account,
    };
  },
  created() {
    this.$store.commit("FeedBack/UPDATE_TITLE", null);
    this.$store.commit("FeedBack/UPDATE_DESCRIPTION", null);
  },
  methods: {
    goToTickets() {
      this.$router.push({ name: "SupportPage" });
    },
    sendTicket() {
      this.$store.commit("FeedBack/SET_SUPPORT_TYPE", this.supportType);
      this.$store.commit("FeedBack/SET_ACCOUNT", this.account);
      this.$store.dispatch("FeedBack/createTicket", (message) => {
        this.dialogMessage = message;
        this.showDialog = true;
      });
    },
  },
};
</script>
<style scoped>
@import "../../../../node_modules/vant/lib/dialog/index.css";
@import "../../../../node_modules/vant/lib/overlay/index.css";
@import "../../../../node_modules/vant/lib/popup/index.css";
</style>

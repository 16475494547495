var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"itserve-input",class:'size-' + _vm.variant},[_c('div',{staticClass:"input-wrapper"},[_c('label',{staticClass:"itserve-label textarea-label",class:_vm.formErrors !== null &&
        Object.keys(_vm.formErrors.errors).indexOf(_vm.name) >= 0
          ? "error-label label-show-up show-label"
          : "standard-label " +
            (_vm.content.length ? 'label-show-up show-label' : ''),attrs:{"for":'_itserve-textarea-' + _vm.name}},[_vm._v(_vm._s(_vm.formErrors !== null && Object.keys(_vm.formErrors.errors).indexOf(_vm.name) >= 0 ? _vm.formErrors.errors[_vm.name][0] : _vm.placeholder))]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.content),expression:"content"}],staticClass:"itserve-textarea-field",class:_vm.formErrors !== null &&
        Object.keys(_vm.formErrors.errors).indexOf(_vm.name) >= 0
          ? 'error-field'
          : '',attrs:{"type":_vm.type,"disabled":_vm.disabled,"name":_vm.name,"placeholder":_vm.placeholder},domProps:{"value":(_vm.content)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.content=$event.target.value},function($event){return _vm.changeModelValue(_vm.name)}]}})])])}
var staticRenderFns = []

export { render, staticRenderFns }
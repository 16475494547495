<template>
  <van-row justify="center" class="page-wrapper">
    <van-col span="24" class="round-shadow-btn faq-search-menu">
      <itserve-input
        :placeholder="$t('faq.writeQuestion')"
        v-model="faqSearch"
        @input="sortFaq"
        class="faq-page-input apply-loader"
      />
    </van-col>
    <van-col
      span="24"
      class="bottom-content-grow no-padding faq-list flex-display-fix"
    >
      <template v-if="faq !== null">
        <div class="place-content-center">
          <van-collapse v-model="activeNames">
            <template v-for="qAndA in faq">
              <van-collapse-item
                class="answer-block apply-loader"
                v-bind:key="qAndA.id"
                :name="qAndA.id"
                value-class="answer-content"
              >
                <template #title>
                  <div class="title-text">
                    {{ qAndA.question }}
                  </div>
                </template>

                {{ qAndA.answer }}</van-collapse-item
              >
            </template>
          </van-collapse>
        </div>
      </template>
    </van-col>
  </van-row>
</template>
<script>
import CollapseItem from "vant/lib/collapse-item/index";
import Collapse from "vant/lib/collapse/index";
import ItserveInput from "@/components/forms/ItserveInput";
export default {
  name: "Index",
  data() {
    return {
      activeNames: [],
      faqSearch: null,
      faqSearchTimeout: null,
    };
  },
  components: {
    ItserveInput,
    VanCollapseItem: CollapseItem,
    VanCollapse: Collapse,
  },
  computed: {
    faq() {
      return this.$store.state.Faq.faqData;
    },
    locale() {
      return this.$store.state.Auth.locale;
    },
  },
  created() {
    this.$store.dispatch("Faq/getFaqData", this.locale);
  },
  methods: {
    sortFaq() {
      clearTimeout(this.faqSearchTimeout);
      this.faqSearchTimeout = setTimeout(() => {
        if (this.faqSearch !== null && this.faqSearch.trim() !== "") {
          this.$store.dispatch("Faq/searchInFaq", this.faqSearch);
        } else {
          this.$store.dispatch("Faq/searchInFaq", null);
        }
      }, 500);
    },
  },
};
</script>
<style>
.faq-list .title-text {
  text-align: center;
  font-weight: bold;
}
</style>
<style scoped>
@import "../../../node_modules/vant/lib/collapse-item/index.css";

.faq-search-menu {
  min-height: 100px;
}
.no-padding {
  padding-top: 0 !important;
}
.faq-page-input {
  margin-left: 25px;
  margin-right: 23px;
  margin-top: 15px;
}
.title-text {
  padding: 0px 40px 0px 15px;
  position: relative;
  color: #0b0b0b;
  font-size: 16px;
}
.title-icon {
  position: absolute;
  right: 0px;
  top: 0px;
  color: #918dab;
}
.answer-block {
  border-bottom: 1px solid #918dab;
}
.answer-block .van-cell {
  border-bottom: 1px solid #918dab;
}
</style>
<style>
.van-collapse-item__content {
  color: #918dab;
  font-size: 14px;
  padding-left: 30px !important;
}
</style>

<template>
  <svg
    width="100%"
    height="100%"
    style="margin-top: 3px"
    viewBox="-32 -17 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.0076 64C12.5677 64 0 51.371 0 35.8559C0 24.9983 6.32185 14.9959 16.1085 10.3842C16.3213 10.2773 16.5493 10.2314 16.7924 10.2314C17.4155 10.2314 17.9929 10.5979 18.2665 11.163C18.6464 11.9723 18.2969 12.9497 17.4915 13.3314C8.84451 17.424 3.2521 26.2658 3.2521 35.8711C3.2521 49.5843 14.3609 60.7473 28.0076 60.7473C41.6543 60.7473 52.7631 49.5843 52.7631 35.8711C52.7631 26.2658 47.1707 17.424 38.5086 13.3314C37.7031 12.9497 37.3536 11.9723 37.7335 11.163C37.9919 10.5979 38.5693 10.2314 39.2076 10.2314C39.4507 10.2314 39.6787 10.2773 39.8915 10.3842C49.6782 14.9959 56 24.9983 56 35.8711C56 51.371 43.4475 64 28.0076 64Z"
      fill="white"
    />
    <path
      d="M28.0076 63.1296C13.0388 63.1296 0.866211 50.8977 0.866211 35.856C0.866211 25.3344 6.9905 15.6374 16.4885 11.163C16.8684 10.9798 17.3243 11.1478 17.5066 11.5295C17.689 11.9113 17.5218 12.3694 17.1419 12.5527C8.19104 16.7827 2.40108 25.9299 2.40108 35.8712C2.40108 50.0731 13.8898 61.6178 28.0228 61.6178C42.1558 61.6178 53.6445 50.0731 53.6445 35.8712C53.6445 25.9299 47.8545 16.7827 38.9037 12.5527C38.5237 12.3694 38.3566 11.9113 38.5389 11.5295C38.7213 11.1478 39.1772 10.9798 39.5571 11.163C49.0399 15.6374 55.1794 25.3344 55.1794 35.856C55.149 50.8977 42.9612 63.1296 28.0076 63.1296Z"
      fill="white"
    />
    <path
      d="M28.0074 23.7003C27.1108 23.7003 26.3813 22.9673 26.3813 22.0663V1.63398C26.3813 0.732999 27.1108 0 28.0074 0C28.904 0 29.6334 0.732999 29.6334 1.63398V22.0816C29.6183 22.9673 28.904 23.7003 28.0074 23.7003Z"
      fill="white"
    />
    <path
      d="M28.0074 22.8451C27.5819 22.8451 27.2476 22.5092 27.2476 22.0816V1.63396C27.2476 1.20638 27.5819 0.870422 28.0074 0.870422C28.4329 0.870422 28.7672 1.20638 28.7672 1.63396V22.0816C28.7672 22.4939 28.4177 22.8451 28.0074 22.8451Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "shutdown-icon-box",
};
</script>

<style scoped></style>

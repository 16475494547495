<template>
  <svg
    width="100%"
    height="100%"
    style="margin-top: 9px"
    viewBox="-13 -17 120 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.0508 64C2.26499 64 0 61.8212 0 59.1415V4.85855C0 2.17877 2.26499 0 5.0508 0H93.0946C95.8804 0 98.1454 2.17877 98.1454 4.85855V59.1531C98.1454 61.8329 95.8804 64.0117 93.0946 64.0117H5.0508V64ZM2.57991 59.1531C2.57991 60.458 3.68212 61.5183 5.03869 61.5183H93.0825C94.4391 61.5183 95.5413 60.458 95.5413 59.1531V25.5045H2.57991V59.1531ZM95.5534 23.0228V20.5061H2.57991V23.0228H95.5534ZM95.5534 18.0127V4.85855C95.5534 3.55361 94.4512 2.49336 93.0946 2.49336H5.0508C3.69423 2.49336 2.59202 3.55361 2.59202 4.85855V18.0244H95.5534V18.0127Z"
      fill="white"
    />
    <path
      d="M93.0835 0.664307H5.05181C2.65359 0.664307 0.691406 2.54015 0.691406 4.85874V59.1533C0.691406 61.4602 2.64148 63.3477 5.05181 63.3477H93.0957C95.4939 63.3477 97.4561 61.4719 97.4561 59.1533V4.85874C97.4439 2.54015 95.4939 0.664307 93.0835 0.664307ZM5.05181 1.82943H93.0957C94.8277 1.82943 96.2448 3.19262 96.2448 4.85874V18.6887H1.90263V4.85874C1.90263 3.18096 3.30765 1.82943 5.05181 1.82943ZM96.2327 19.8422V23.6754H1.90263V19.8422H96.2327ZM93.0835 62.171H5.05181C3.31976 62.171 1.90263 60.8078 1.90263 59.1416V24.8405H96.2448V59.1416C96.2327 60.8194 94.8277 62.171 93.0835 62.171Z"
      fill="white"
    />
    <path d="M25.8596 37.9129H10.4165V40.3946H25.8596V37.9129Z" fill="white" />
    <path d="M25.1809 38.5771H11.1064V39.7422H25.1809V38.5771Z" fill="white" />
    <path d="M43.059 45.5795H10.4165V48.0612H43.059V45.5795Z" fill="white" />
    <path d="M42.3802 46.2318H11.1064V47.3969H42.3802V46.2318Z" fill="white" />
    <path
      d="M78.1118 51.9061V49.4127C76.7552 49.2845 75.4108 48.8534 74.5387 48.2592L74.1147 47.968L75.1927 45.0785L75.9195 45.5445C76.8279 46.1271 78.0512 46.4766 79.1777 46.4766C80.5706 46.4766 81.5032 45.7892 81.5032 44.7522C81.5032 44.0648 81.2125 43.3075 79.0808 42.4686C76.8158 41.6181 74.4902 40.3714 74.4902 37.6566C74.4902 35.4779 75.9679 33.7768 78.3056 33.2059V30.631H81.2489V33.0311C82.3511 33.136 83.3201 33.4389 84.1921 33.9283L84.6887 34.2079L83.5623 37.0624L82.8598 36.6779C82.4601 36.4566 81.5153 35.9439 80.0134 35.9439C78.5478 35.9439 78.027 36.678 78.027 37.377C78.027 38.1227 78.4146 38.612 80.7401 39.5325C82.7629 40.3248 85.0763 41.6181 85.0763 44.5891C85.0763 46.8378 83.4896 48.6554 81.0793 49.2496V51.9061H78.1118Z"
      fill="white"
    />
    <path
      d="M80.4607 40.1383C78.2684 39.2645 77.3236 38.6353 77.3236 37.3653C77.3236 36.3983 78.0503 35.2798 80.0004 35.2798C81.6113 35.2798 82.653 35.8157 83.1859 36.107L83.8157 34.4992C83.0769 34.0914 82.0474 33.6952 80.5333 33.6486V31.2951H78.9709V33.7535C76.6453 34.1147 75.1555 35.6526 75.1555 37.6683C75.1555 39.7771 76.7422 40.8957 79.3221 41.8744C81.1874 42.6084 82.1806 43.424 82.1806 44.7638C82.1806 46.1503 80.9694 47.1407 79.1768 47.1407C77.7597 47.1407 76.4515 46.6746 75.5431 46.0921L74.9375 47.7233C75.8217 48.3291 77.3357 48.7719 78.8013 48.7952V51.2536H80.388V48.7253C82.9921 48.3175 84.385 46.5115 84.385 44.5891C84.385 42.422 83.0648 41.1636 80.4607 40.1383Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "pay-icon-box",
};
</script>

<style scoped></style>

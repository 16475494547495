<template>
  <van-row class="home-page" justify="center">
    <van-col class="itserve-center" span="24">
      <accounts class="home-accounts-list mt-15 place-content-center" />
    </van-col>
    <template v-if="Object.keys(accounts).length">
      <van-col class="itserve-center" span="24">
        <itserve-route
          :buttonName="$t('buttons.addAccount')"
          route-name="AddAccount"
          type="wide"
        ></itserve-route>
      </van-col>
    </template>
  </van-row>
</template>
<script>
import Accounts from "@/components/helpers/Accounts";
import ItserveRoute from "@/components/content/ItserveRoute";
export default {
  name: "Home",
  created() {
    this.getAccounts();
  },
  components: {
    ItserveRoute,
    Accounts,
  },
  computed: {
    accounts() {
      return this.$store.state.Auth.accounts;
    },
  },
  methods: {
    async getAccounts() {
      await this.$store.dispatch("Auth/getUserAccounts");
      if (Object.keys(this.accounts).length === 0) {
        await this.$router.push({ name: "AddAccount" });
      }
    },
  },
};
</script>
<style scoped>
@import "../../node_modules/vant/lib/notice-bar/index.css";
@import "../../node_modules/vant/lib/list/index.css";
.home-accounts-list {
  margin-bottom: 15px;
}
.home-page {
  background-color: #fff;
  padding-bottom: 15px;
  flex-grow: 1;
}
</style>
<style>
.home-accounts-list .home-account {
  border-radius: 10px;
  max-width: 357px;
  -webkit-box-shadow: 0px 2px 3px 2px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 2px 3px 2px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 2px 3px 2px rgba(34, 60, 80, 0.2);
}
</style>

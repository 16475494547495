<template>
  <arrow-left
    class="itserve-menu-icon"
    :fill-color="appTheme === 'dark' ? '#0562ad' : '#ffffff'"
    @click="goBack"
  />
</template>

<script>
import goBack from "@/mixins/go-back";
import ArrowLeft from "vue-material-design-icons/ArrowLeft";
export default {
  mixins: [goBack],
  name: "BackButton",
  computed: {
    appTheme() {
      return this.$store.state.Auth.appTheme;
    },
  },
  components: {
    ArrowLeft,
  },
};
</script>

<style scoped>
.itserve-menu-icon {
  position: relative;
  top: 9px;
}
</style>

<template>
  <van-dialog
    v-model="value"
    :title="title"
    :show-cancel-button="false"
    :show-confirm-button="false"
  >
    <slot class="dialog-content"></slot>
    <button
      class="
        van-button van-button--default van-button--largeitserve-app-color
        mt-25
      "
      @click="hideDialog"
    >
      <template v-if="success">
        <v-icon name="check" class="dialog-confirm-icon"></v-icon>
      </template>
      <template v-else>
        <span class="modal-custom-ok">{{ $t("buttons.modalOkBtn") }}</span>
      </template>
    </button>
  </van-dialog>
</template>

<script>
import "vue-awesome/icons/check";
import Dialog from "vant/lib/dialog/index";
export default {
  name: "SimpleDialog",
  props: {
    title: String,
    value: Boolean,
    success: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      closeTimeout: null,
    };
  },
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  watch: {
    value: function (val) {
      if (val === true) {
        this.closeTimeout = setTimeout(() => {
          // this.hideDialog();
        }, 5000);
      } else {
        clearTimeout(this.closeTimeout);
      }
    },
  },
  methods: {
    hideDialog() {
      this.$emit("input", false);
      this.$emit("change");
    },
  },
};
</script>

<style scoped>
@import "../../../node_modules/vant/lib/dialog/index.css";
@import "../../../node_modules/vant/lib/overlay/index.css";
@import "../../../node_modules/vant/lib/popup/index.css";
.dialog-confirm-icon {
  width: 24px;
  height: 24px;
  color: #1aad62;
}

.modal-custom-ok {
  font-weight: bold;
  color: #2e73b4;
}
</style>

<template>
  <van-row justify="center" class="page-wrapper have-bottom-menu">
    <van-col span="24" class="itserve-center">
      <info-message>
        {{ $t("regionalEnergy.enterpriseServices.comingSoon") }}</info-message
      >
    </van-col>
  </van-row>
</template>
<script>
import InfoMessage from "@/components/content/InfoMessage";
export default {
  name: "EnterpriseServices",
  components: { InfoMessage },
};
</script>

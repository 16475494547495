<template>
  <van-row justify="center" class="page-wrapper">
    <van-col span="24" class="flex-fix itserve-center round-shadow-btn">
      <div class="place-content-center">
        <template v-if="cards.length">
          <info-message class="apply-loader">
            {{ $t("paymentCards.pushAtCard") }}
          </info-message>
        </template>
        <template v-else>
          <info-message class="apply-loader">{{
            $t("paymentCards.listOfCardsEmpty")
          }}</info-message>
        </template>
      </div>
    </van-col>

    <van-col span="24" class="cards-list itserve-center">
      <template v-if="cards.length">
        <div class="place-content-center">
          <template v-for="card in cards">
            <van-cell
              class="cards-list-cell shadow-white mb-25 single-card"
              :title="card.pan"
              :key="card.pan"
              value=""
              @click="deleteCard(card.card_token)"
            >
              <template #icon>
                <template v-if="card.pan.charAt(0) === '4'">
                  <img src="@/assets/images/visa.png" class="card-type-image" />
                </template>
                <template v-else>
                  <img
                    src="@/assets/images/mastercard.png"
                    class="card-type-image"
                  />
                </template>
              </template>
            </van-cell>
          </template>
        </div>
      </template>
      <itserve-route
        route-name="AddCard"
        :params="this.$route.params"
        :buttonName="$t('buttons.addCard')"
        class="mt-15"
      ></itserve-route>
    </van-col>
    <template v-if="!getPhone">
      <div class="place-content-center">
        <van-form>
          <info-message class="apply-loader settings-message">
            {{ $t("settings.addPhone") }}
          </info-message>
          <itserve-input
            v-model="newPhone"
            inputmode="tel"
            type="text"
            class="apply-loader fl-upper"
            :placeholder="$t('settings.phone')"
            name="name"
          />
        </van-form>
        <itserve-button
          type="primary"
          class="itserve-round-btn itserve-button-wide mb-25 apply-loader"
          :value="$t('buttons.saveChanges')"
          @click="addPhone"
        ></itserve-button>
      </div>
    </template>
    <simple-dialog
      v-model="showDialog"
      :success="actionSuccessfully"
      :title="dialogMessage"
      @change="getCards"
    />
    <simple-dialog
        v-model="showDialog2"
        :success="actionSuccessfully"
        :title="dialogMessage"
        @change="confirm"
    />
    <simple-dialog
        v-model="showDialog3"
        :success="actionSuccessfully"
        :title="dialogMessage"
    />
  </van-row>
</template>
<script>
import Dialog from "vant/lib/dialog/index";
import Cell from "vant/lib/cell/index";
import InfoMessage from "@/components/content/InfoMessage";
import "vue-awesome/icons/plus";
import ItserveRoute from "@/components/content/ItserveRoute";
import SimpleDialog from "@/components/content/SimpleDialog";
import ItserveInput from "@/components/forms/ItserveInput";
import ItserveButton from "@/components/forms/ItserveButton";
import Form from "vant/lib/form/index";
export default {
  name: "PaymentCards",
  components: {
    ItserveButton,
    ItserveInput,
    SimpleDialog,
    ItserveRoute,
    InfoMessage,
    VanCell: Cell,
    VanForm: Form,
  },
  computed: {
    getPhone() {
      return this.$store.state.Settings.phone.phone_for_save_card;
    },
    cards() {
      return this.$store.state.Payment.cards;
    },
    addCardUrl() {
      return this.$store.state.Payment.addCardUrl;
    },
    newPhone: {
      get() {
        return this.$store.state.Settings.newPhone;
      },
      set(newPhone) {
        this.$store.commit("Settings/UPDATE_PHONE", newPhone);
        this.dialogMessage = this.$t("settings.successMessage");
      },
    },
  },
  data() {
    return {
      showDialog: false,
      showDialog2: false,
      showDialog3: false,
      dialogMessage: "",
      actionSuccessfully: false,
    };
  },
  created() {
    this.$store.dispatch("Payment/getCardsList");
    this.$store.dispatch("Settings/getPhone");
  },
  methods: {
    async getCards() {
      await this.$store.dispatch("Payment/getCardsList");
    },
    deleteCard(token) {
      Dialog.confirm({
        title: this.$t("paymentCards.dialogDeleteMessage"),
        message: this.$t("paymentCards.dialogQuestion"),
        cancelButtonText: this.$t("paymentCards.dialogCancel"),
        confirmButtonText: this.$t("paymentCards.dialogConfirm"),
      })
        .then(async () => {
          let res = await this.$store.dispatch("Payment/deleteCard", token);
          if (Object.keys(res).indexOf("mode") >= 0) {
            if (res.mode === 1) {
              this.dialogMessage = this.$t("paymentCards.dialogSuccess");
              this.actionSuccessfully = true;
            } else {
              this.dialogMessage = this.$t("paymentCards.dialogError");
              this.actionSuccessfully = false;
            }
          } else {
            this.dialogMessage = this.$t("paymentCards.dialogError");
            this.actionSuccessfully = false;
          }
          this.showDialog = true;
        })
        .catch(() => {});
    },
    addPhone() {
      if (this.$store.state.Settings.newPhone === null) {
        this.showDialog3 = true;
        this.dialogMessage = this.$t("settings.emptyField");
        this.actionSuccessfully = false;
      }
      if (this.$store.state.Settings.newPhone.length !== 13 || this.$store.state.Settings.newPhone.substr(0, 1) !== "+") {
        this.showDialog3 = true;
        this.dialogMessage = this.$t("settings.falseMessage");
        this.actionSuccessfully = false;
      } else {
        this.$store.dispatch("Settings/storePhone").then((res) => {
          if (res) {
            this.showDialog2 = true;
          } else {
            this.showDialog2 = true;
            this.dialogMessage = this.$t("settings.error");
          }
        });
      }
    },
    confirm() {
      if(this.dialogMessage !== this.$t("settings.error")){
        location.reload();
      }
    },
  },
};
</script>
<style scoped>
@import "../../../node_modules/vant/lib/cell/index.css";
@import "../../../node_modules/vant/lib/dialog/index.css";
@import "../../../node_modules/vant/lib/overlay/index.css";
@import "../../../node_modules/vant/lib/popup/index.css";
.cards-list {
  padding-top: 25px;
}
.cards-list-cell {
  font-size: 18px !important;
}
.card-type-image {
  height: 20px;
  margin-right: 5px;
}
</style>
